import { RouteComponentProps } from '@reach/router';
import React, { useCallback } from 'react';
import { navigate } from 'gatsby';

import SEO from '../../../components/seo';
import MessageCenterDashboard from '../../../components/user/message-center/dashboard';
import { getMessages } from '../../../apis/users/message-center';
import { IGetMessagesRequestParams } from '../../../types/dto/message-center';
import { getDisplayName } from '../../../utils/users';
import { IMessage, IMessageThread, UserTableDataSourceType } from '../../../types/models/message-center';

function parseMessage(thread: IMessageThread): UserTableDataSourceType {
  const {
    messages: [message],
  } = thread;
  return {
    ...thread,
    from: getDisplayName((message as IMessage).from),
    body: message.body,
    date: message.date,
  };
}

const Dashboard = (_: RouteComponentProps) => {
  const handleRequest = async ({ params }: { params: IGetMessagesRequestParams }) => {
    const { data, total } = await getMessages(params);
    return {
      data: data.map(parseMessage),
      success: true,
      total,
    };
  };
  const handleCreate = useCallback(() => {
    navigate('/myhsf/message-center/new');
  }, []);

  const handleMessageDetails = useCallback((threadId: string) => {
    navigate(`/myhsf/message-center/${threadId}`);
  }, []);
  return (
    <>
      <SEO title="Message Center" />
      <MessageCenterDashboard
        title="Message Center"
        onRequest={handleRequest}
        onCreate={handleCreate}
        onMessageDetails={handleMessageDetails}
      />
    </>
  );
};

export default Dashboard;
