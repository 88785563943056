import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useQuery } from 'react-query';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { Breadcrumb } from 'antd';
import styled from '@emotion/styled';

import { NavigationWrapper } from '../../../../components/common/page-header';
import { ScholarshipDetail } from '../common/scholarship-detail';
import { getScholarship } from '../../../../apis/users/scholarship-match';
import { StyledContainer } from '../common/styles';
import { Alert } from '../../../../components';
import Loader from '../../../../components/common/loader';
import { generateBreadcrumb } from '../common/utils';
import theme from '../../../../theme';

export function ScholarshipMatchDetail({ id }: RouteComponentProps<{ id: string }>) {
  const {
    data: scholarship,
    isLoading,
    error,
  } = useQuery(!!id && [getScholarship.QUERY_KEY, { id }], ({ queryKey }: { queryKey: [string, { id: string }] }) =>
    getScholarship(queryKey[1].id)
  );

  const renderContent = () => {
    if (isLoading) {
      return <Loader />;
    }
    if (error) {
      return <Alert type="error" message={error.message} />;
    }

    return (
      <>
        <NavigationWrapper>
          <Breadcrumb separator={<NavigateNext />}>
            {generateBreadcrumb(
              { Home: '/' },
              { 'Recommended Scholarships': '/myhsf/scholarships' },
              { [String(scholarship?.name)]: '' }
            ).map((item, index) => (
              <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </NavigationWrapper>
        <ScholarshipDetail data={scholarship} />
      </>
    );
  };

  return (
    <StyledWhiteBackground>
      <StyledContainer>{renderContent()}</StyledContainer>
    </StyledWhiteBackground>
  );
}

export default ScholarshipMatchDetail;

const StyledWhiteBackground = styled.div`
  background-color: ${theme.colorsWhite};
  min-height: 80vh;
`;
