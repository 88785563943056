import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Col, Form, Row, Select } from 'antd';

import { FORM_STATUS_DICTIONARY } from '../../../components/admin/common/constants';
import { CampaignType } from '../../../types/models/program-management';
import { JOB_CAMPAIGN_TYPE_LABELS } from '../../../constants';
import { FormStatus } from '../../../types/form';
import theme from '../../../theme';

const { Option } = Select;

type CareerCenterFiltersProps = {
  typeValue: string[];
  statusValue: string[];
  handleTypeFilterOnChange: (value: string[]) => void;
  handleStatusFilterOnChange: (value: string[]) => void;
};

const MainFiltersSection = styled.div`
  width: 100%;
  padding-bottom: 16px;
  background: ${theme.colorsWhite};
`;
const MainFiltersContainer = styled.div`
  max-width: ${theme.screenXl};
  margin: 0 auto;
  @media (max-width: ${theme.screenLgMax}) {
    padding: 0 24px;
    margin: 0;
    max-width: none;
  }
`;
const Caption = styled.div`
  color: ${theme.colorsBlack};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
  font-weight: bold;
`;

const CareerCenterFilters = (props: CareerCenterFiltersProps) => {
  const { typeValue, statusValue, handleTypeFilterOnChange, handleStatusFilterOnChange } = props;

  const MAX_TAG_COUNT = 1;
  const MAX_TAG_TEXT_LENGTH = 10;
  const MULTIPLE_SELECT_PLACEHOLDER = 'Select one or more';

  const typeOptions = useMemo(
    () => Object.values(CampaignType).map(value => ({ value, label: JOB_CAMPAIGN_TYPE_LABELS[value] })),
    []
  );

  const statusOptions = useMemo(
    () =>
      Object.values([FormStatus.Completed, FormStatus.InProgress, FormStatus.NotStarted]).map(value => ({
        value,
        label: FORM_STATUS_DICTIONARY[value],
      })),
    []
  );

  const Type: JSX.Element[] = typeOptions?.map((item: any, index: number) => (
    <Option key={index} value={item.value}>
      {item.label}
    </Option>
  ));

  const Status: JSX.Element[] = statusOptions?.map((item: any, index: number) => (
    <Option key={index} value={item.value}>
      {item.label}
    </Option>
  ));

  return (
    <MainFiltersSection>
      <MainFiltersContainer>
        <Form name="basic" colon={false}>
          <Row gutter={[32, 18]} align="middle">
            <Col xs={24} xl={6}>
              <Caption>Opportunity type</Caption>
              <Select
                size="large"
                mode="multiple"
                value={typeValue}
                maxTagCount={MAX_TAG_COUNT}
                maxTagTextLength={MAX_TAG_TEXT_LENGTH}
                optionFilterProp="children"
                placeholder={MULTIPLE_SELECT_PLACEHOLDER}
                onChange={handleTypeFilterOnChange}
              >
                {Type}
              </Select>
            </Col>
            <Col xs={24} xl={6}>
              <Caption>Status</Caption>
              <Select
                size="large"
                mode="multiple"
                value={statusValue}
                maxTagCount={MAX_TAG_COUNT}
                maxTagTextLength={MAX_TAG_TEXT_LENGTH}
                optionFilterProp="children"
                placeholder={MULTIPLE_SELECT_PLACEHOLDER}
                onChange={handleStatusFilterOnChange}
              >
                {Status}
              </Select>
            </Col>
          </Row>
        </Form>
      </MainFiltersContainer>
    </MainFiltersSection>
  );
};

export default CareerCenterFilters;
