import React, { useEffect } from 'react';
import { Redirect, Router } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';

import Admin from './admin';

import { isDiscernmentSurveyEnabled, isHSFUniversityEnabled } from '../state/selectors/tenant';
import AccountSettings from '../containers/myhsf/account-settings';
import AvailableMentorsList from '../containers/myhsf/mentorship/manual-application/mentors-list/manual-mentors-match-list';
import CareerCenter from '../containers/myhsf/career-center';
import CoachBio from '../containers/myhsf/coaching/coach-bio/coach-bio';
import CoacheeBio from '../containers/myhsf/coaching/coachee-bio/coachee-bio';
import CoacheesList from '../containers/myhsf/coaching/coachees-list/coachees-list';
import CoacheesMatch from '../containers/myhsf/coaching/coachee-match';
import CoachesList from '../containers/myhsf/coaching/coaches-list/coaches-list';
import CoachesMatch from '../containers/myhsf/coaching/coach-match';
import Cohorts from '../containers/myhsf/cohorts';
import CollegeNavigator from '../containers/myhsf/college-navigator';
import Dashboard from '../containers/myhsf/dashboard';
import DiscernmentSurvey from '../containers/myhsf/discernment-survey';
import DiscernmentSurveyForm from '../containers/myhsf/discernment-survey/survey-form';
import DiscernmentSurveyResults from '../containers/myhsf/discernment-survey/survey-results';
import DocumentBank from '../containers/myhsf/document-bank';
import Essay from '../containers/myhsf/essay';
import EventAgendaDetail from '../containers/myhsf/events/agenda-detail';
import EventAttendeeBio from '../containers/myhsf/events/event-attendee-bio';
import EventDetails from '../containers/myhsf/events/event-details';
import EventDirectory from '../containers/myhsf/events/event-directory';
import Events from '../containers/myhsf/events';
import Form from '../containers/myhsf/form';
import JobDetails from '../containers/myhsf/career-center/job-details';
import MenteeBio from '../containers/myhsf/mentorship/mentee-bio/mentee-bio';
import MenteesList from '../containers/myhsf/mentorship/mentees-list/mentees-list';
import MentorBio from '../containers/myhsf/mentorship/mentor-bio/mentor-bio';
import MentorsList from '../containers/myhsf/mentorship/mentors-list/mentors-list';
import Mentorship from '../containers/myhsf/mentorship';
import MessageCenter from '../containers/myhsf/message-center';
import MessageCenterCreateMessage from '../containers/myhsf/message-center/create-message';
import MessageCenterDashboard from '../containers/myhsf/message-center/dashboard';
import MessageDetails from '../containers/myhsf/message-center/message-details';
import MilestoneDetails from '../containers/myhsf/mentorship/common/milestone-details';
import MyCareerCenter from '../containers/myhsf/career-center/my-career-center';
import MyEvents from '../containers/myhsf/events/my-events';
import MyHSFRoute from '../components/common/myhsf-route';
import NotFound from '../components/common/not-found';
import Profile from '../containers/myhsf/profile';
import ProgramDetails from '../containers/myhsf/program-details';
import ScholarShipMatch from '../containers/myhsf/scholarship/scholarship-match';
import ScholarshipMatchDetail from '../containers/myhsf/scholarship/scholarship-match/scholarship-match-detail';
import ScholarshipMatchList from '../containers/myhsf/scholarship/scholarship-match/scholarship-match-list';
import UniversityTrackDetails from '../containers/myhsf/university-track/university-track-details';
import UniversityTrackMatch from '../containers/myhsf/university-track';
import UniversityTracksList from '../containers/myhsf/university-track/university-tracks-list';
import FinancialPortalApp from '../containers/financial-portal-remote-app/financial-portal-app';
import UserLayout from '../layout/user-layout';
import { hasInternalRole } from '../state/selectors/privilege';
import { isAuthenticated } from '../state/selectors/auth';
import { navigateTo } from '../state/actions/routing';

export default function MyHsf() {
  const isInternalUser = useSelector(hasInternalRole());
  const discernmentSurveyEnabled = useSelector(isDiscernmentSurveyEnabled());
  const HSFUniversityEnabled = useSelector(isHSFUniversityEnabled());
  const loggedIn = useSelector(isAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loggedIn) {
      dispatch(navigateTo('/login'));
    }
  }, [loggedIn, dispatch]);

  if (isInternalUser) {
    return <Admin />;
  } else {
    return (
      <UserLayout>
        <Router>
          <MyHSFRoute path="/myhsf">
            {/* FIXME: Workaround where Dashboard markup is included in all other routes in production build */}
            <Redirect from="/" to="/myhsf/dashboard" noThrow />
            <Dashboard path="dashboard" />
            <Form path="form/:stageId" />
            <Profile path="profile" />
            <MessageCenter path="message-center">
              <MessageCenterDashboard path="/" />
              <MessageCenterCreateMessage path="new" />
              <MessageDetails path=":threadId" />
            </MessageCenter>
            <ProgramDetails path="programs/:programId" />
            <DocumentBank path="document-bank" />
            <CollegeNavigator path="college-navigator" />
            <Cohorts path="scholars" />
            <AccountSettings path="account" />
            <Essay path="essays/:stageId" />
            <ScholarShipMatch path="scholarships">
              <ScholarshipMatchList path="/" />
              <ScholarshipMatchDetail path=":id" />
            </ScholarShipMatch>
            <Events path="events">
              <MyEvents path=":activeTab" />
              <EventDetails path="event/:eventId" />
              <EventAgendaDetail path="event/:eventId/agenda/:agendaId" />
              <EventDirectory path="event/:eventId/directory" />
              <EventAttendeeBio path="event/:eventId/attendee/:userId" />
            </Events>
            <CareerCenter path="career-center">
              <MyCareerCenter path="/" />
              <JobDetails path="job-details/:stageId" />
            </CareerCenter>
            {discernmentSurveyEnabled && (
              <DiscernmentSurvey path="discernment-survey">
                <Redirect from="/" to="/myhsf/discernment-survey/results" noThrow />
                <DiscernmentSurveyForm path="form" />
                <DiscernmentSurveyForm path="form/:id" />
                <DiscernmentSurveyResults path="results" />
              </DiscernmentSurvey>
            )}
            <DiscernmentSurvey path="discernment-survey" />
            {HSFUniversityEnabled && (
              <UniversityTrackMatch path="tracks">
                <UniversityTracksList path="/" />
                <UniversityTracksList path="/recommended" />
                <UniversityTracksList path="/inprogress" />
                <UniversityTracksList path="/completed" />
                <UniversityTrackDetails path=":id" />
              </UniversityTrackMatch>
            )}
            <CoachesMatch path="my-coaches">
              <CoachesList path={'/'} />
              <CoachBio path={'/:userId'} />
            </CoachesMatch>
            <CoacheesMatch path="my-coachees">
              <CoacheesList path={'/'} />
              <CoacheeBio path={':userId'} />
            </CoacheesMatch>
            <Mentorship path={'my-mentors'}>
              <MentorsList path={'/'} />
              <MentorBio path={':userId'} />
              <AvailableMentorsList path={'/search'} />
            </Mentorship>
            <Mentorship path={'my-mentees'}>
              <MenteesList path={'/'} />
              <MenteeBio path={'mentee/:userId'} />
            </Mentorship>
            <MilestoneDetails path={'milestone-details'} />
            <FinancialPortalApp path="financial-portal/*" />
            <NotFound default />
          </MyHSFRoute>
        </Router>
      </UserLayout>
    );
  }
}
