import { RouteComponentProps } from '@reach/router';
import React, { useState, useEffect } from 'react';
import { filter, find, get, isNil } from 'lodash';
import { Link, navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { useQuery } from 'react-query';
import { Spin } from 'antd5';

import { NoMentees } from './no-mentees-list';

import { StyledMessage } from '../../scholarship/common/styles';
import SEO from '../../../../components/seo';
import { getUser, useRoles } from '../../../../state/selectors/auth';
import HorizontalSecondaryMenu from '../../../../components/common/horizontal-secondary-menu';
import PageHeader from '../../../../components/common/page-header';
import { InternalContainer } from '../../../../templates/basic-styles';
import UserDetailsContainer from '../common/user-details-container';
import MilestonesContainer from '../common/milestones-container';
import { tenantLabel } from '../../../../siteContent';
import { IUser } from '../../../../types/models/auth';
import { getAssignedMentees, getMilestonesList } from '../../../../apis/users/mentorship';
import MenteeCompleteMilestoneSurveyModal from '../mentee-complete-milestone-survey/mentee-complete-milestone-survey';
import { openChat, setIsLoadingChat } from '../../../../state/actions/message';
import Chat from '../../../../components/chat/index';
import {
  isMentorMatchEnabled,
  isConferenceMentorMatchEnabled,
  isRealTimeChatEnabled as $isRealTimeChatEnabled,
} from '../../../../state/selectors/tenant';
import { MentorshipActor } from '../../../../types/models/mentorship';

const StyledHorizontalMenu = styled.div`
  background-color: white;
`;

const SectionWrapper = styled.div`
  margin-bottom: 20px;
  background-color: white;
  position: relative;
  margin-bottom: 20px;
`;

const SectionTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: black;
  margin: 25px;
`;

const PartnerBorder = styled.hr`
  background-color: #f0f0f0;
  width: 95%;
  height: 1px;
  border-style: none;
  margin: auto;
`;

const MenteesList = (_: RouteComponentProps) => {
  const menuItems = [
    {
      name: 'activeMentees',
      value: 'Active Mentees',
      slug: '/mentor-match',
    },
    {
      name: 'pastMentees',
      value: 'Past Mentees',
      slug: '/mentor-match',
    },
  ];
  const loggedUser: IUser | null = useSelector(getUser);
  const [activeNavItem, setActiveNavItem] = useState(menuItems[0]);
  const [completeSurveyModalVisible, setCompleteSurveyModalVisible] = useState(false);
  const [selectedMenteeId, setSelectedMenteeId] = useState('');
  const [selectedMilestoneId, setSelectedMilestoneId] = useState('');
  const [annualMentees, setAnnualMentees] = useState([] as MentorshipActor[]);
  const [programMentees, setProgramMentees] = useState([] as MentorshipActor[]);
  const dispatch = useDispatch();
  const isRealTimeChatEnabled = useSelector($isRealTimeChatEnabled());

  const isMentorMatchEnable = useSelector(isMentorMatchEnabled());
  const isConferenceMentorMatchEnable = useSelector(isConferenceMentorMatchEnabled());
  const { isMentor, isProgramMentor } = useRoles();

  const {
    data: userData,
    isLoading: isUserDataLoading,
    refetch: refetchUserData,
    error,
  } = useQuery(loggedUser && [getAssignedMentees.QUERY_KEY, { userId: loggedUser.id }], () => getAssignedMentees());
  const { data: milestonesData, isLoading: isMilestonesDataLoading } = useQuery(getMilestonesList.QUERY_KEY, () =>
    getMilestonesList()
  );

  const isActiveMenteesTab = (): boolean => {
    return activeNavItem.name === 'activeMentees';
  };

  useEffect(() => {
    const mentees = filter(userData, function (mentee) {
      if (isActiveMenteesTab()) {
        return isNil(mentee.completedDate);
      } else {
        return !isNil(mentee.completedDate);
      }
    });

    if (isMentorMatchEnable && isMentor) {
      setAnnualMentees(filter(mentees, (mentee) => isNil(mentee.programId)));
    }
    if (isConferenceMentorMatchEnable && isProgramMentor) {
      setProgramMentees(filter(mentees, (mentee) => !isNil(mentee.programId)));
    }
  }, [userData, isMentorMatchEnable, isConferenceMentorMatchEnable, activeNavItem]);

  const handleOpenMenteeBio = (userId: string) => {
    navigate(`/myhsf/my-mentees/mentee/${userId}`);
  };

  const handleSendMessage = (userId: string) => {
    dispatch(setIsLoadingChat(true));
    dispatch(openChat(userId));
  };

  const buttons = [
    {
      title: 'Mentee Bio',
      type: 'default',
      handler: handleOpenMenteeBio,
    },
    {
      title: 'Send Message',
      type: 'primary-blue',
      handler: handleSendMessage,
      opensChat: true,
    },
  ];

  const handleSecondaryMenuClick = (sectionName: string) => {
    const navItem = find(menuItems, (item: { name: string }) => {
      return item.name === get(sectionName, 'key');
    });
    if (navItem) {
      setActiveNavItem(navItem);
    }
  };

  const getMilestones = (milestoneType: string): any => {
    return filter(get(milestonesData, 'items', []), function (milestone) {
      return milestoneType === milestone.milestoneType;
    });
  };

  const markDiscussionCompleteHandler = (userId: string, milestoneId: string) => {
    setSelectedMenteeId(userId);
    setSelectedMilestoneId(milestoneId);
    setCompleteSurveyModalVisible(true);
  };

  const renderPageContent = (menteesList: any[]) => {
    if (isUserDataLoading && isMilestonesDataLoading) {
      return undefined;
    }

    return (
      <>
        {userData &&
          menteesList.map((mentee: any) => {
            return (
              <SectionWrapper key={mentee.id}>
                <UserDetailsContainer userData={mentee} buttonsList={buttons} />
                {milestonesData && isActiveMenteesTab() && (
                  <>
                    <PartnerBorder />
                    <MilestonesContainer
                      markDiscussionCompleteHandler={markDiscussionCompleteHandler}
                      finishedCourses={mentee.finishedCourses}
                      milestones={getMilestones(mentee.milestoneType)}
                      completedMilestones={mentee.completedMilestones}
                      userId={mentee.id}
                      milestoneSurvey={mentee.milestoneSurvey}
                    />
                  </>
                )}
              </SectionWrapper>
            );
          })}
      </>
    );
  };

  return (
    <>
      <SEO title="My Mentees" />
      <PageHeader
        title="My Mentees"
        breadcrumb={[
          <Link key="1" to="/myhsf">
            {tenantLabel} Dashboard
          </Link>,
          'My Mentees',
        ]}
        fullWidth
        isResponsive
        noShadow
      />
      <StyledHorizontalMenu>
        <HorizontalSecondaryMenu activeItem={activeNavItem} handleClick={handleSecondaryMenuClick} items={menuItems} />
      </StyledHorizontalMenu>
      <InternalContainer>
        <Spin spinning={isUserDataLoading && isMilestonesDataLoading} size="large">
          {annualMentees.length ? (
            <>
              <SectionTitle>Mentees</SectionTitle>
              {!error && renderPageContent(annualMentees)}
              {error && <StyledMessage>{error.message}</StyledMessage>}
            </>
          ) : null}
          {programMentees.length ? (
            <>
              <SectionTitle>Program Mentees</SectionTitle>
              {!error && renderPageContent(programMentees)}
              {error && <StyledMessage>{error.message}</StyledMessage>}
            </>
          ) : null}
          {!annualMentees.length && !programMentees.length ? <NoMentees active={isActiveMenteesTab()} /> : null}
        </Spin>
        {isRealTimeChatEnabled && <Chat />}
      </InternalContainer>
      <MenteeCompleteMilestoneSurveyModal
        closeModal={() => {
          setCompleteSurveyModalVisible(false);
          setSelectedMenteeId('');
          setSelectedMilestoneId('');
          refetchUserData();
        }}
        isVisible={completeSurveyModalVisible}
        menteeId={selectedMenteeId}
        milestoneId={selectedMilestoneId}
      />
    </>
  );
};

export default MenteesList;
