import { RouteComponentProps } from '@reach/router';
import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';
import { useQuery } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { find, get } from 'lodash';

import { NoCoachees } from './no-coachees';

import SEO from '../../../../components/seo';
import { getUser } from '../../../../state/selectors/auth';
import HorizontalSecondaryMenu from '../../../../components/common/horizontal-secondary-menu';
import PageHeader from '../../../../components/common/page-header';
import { IUser } from '../../../../types/models/auth';
import { InternalContainer } from '../../../../templates/basic-styles';
import { getAssignedCoachees } from '../../../../apis/users/coaches-match';
import UserDetailsContainer from '../../mentorship/common/user-details-container';
import { StyledMessage } from '../../scholarship/common/styles';
import Loader from '../../../../components/common/loader';
import CoacheeCompleteSurveyModal from '../coachee-complete-survey-modal/coachee-complete-survey-modal';
import { Coachee } from '../../../../types/models/coaching';
import { tenantLabel } from '../../../../siteContent';
import { Button } from '../../../../components';
import { pauseCoachCommitment } from '../common/pause-commitment';
import theme from '../../../../theme';
import { getProfile } from '../../../../apis/users/profile';
import { openChat, setIsLoadingChat } from '../../../../state/actions/message';
import Chat from '../../../../components/chat/index';
import { isRealTimeChatEnabled as $isRealTimeChatEnabled } from '../../../../state/selectors/tenant';

const StyledHorizontalMenu = styled.div`
  background-color: white;
`;

const SectionWrapper = styled.div`
  margin: 20px 0;
  background-color: white;
  position: relative;
`;

const StyledButton = styled(Button)`
  &.ant-btn {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
`;

const StyledContainer = styled.div`
  max-width: ${theme.screenXl};
  margin: 0 auto;
  display: flex;
  position: relative;
`;

const CoacheesList: React.FC<RouteComponentProps> = (_: RouteComponentProps) => {
  const dispatch = useDispatch();
  const isRealTimeChatEnabled = useSelector($isRealTimeChatEnabled());

  const menuItems = [
    {
      name: 'activeCoachees',
      value: 'Active Coachees',
      slug: '/coachees',
    },
    {
      name: 'pastCoachees',
      value: 'Past Coachees',
      slug: '/coachees',
    },
  ];

  const loggedUser: IUser | null = useSelector(getUser);
  const [activeNavItem, setActiveNavItem] = useState(menuItems[0]);
  const [completeSurveyModalVisible, setCompleteSurveyModalVisible] = useState(false);
  const [selectedCoacheeId, setSelectedCoacheeId] = useState('');

  const handleOpenCoacheeBio = (userId: string) => {
    navigate(`/myhsf/my-coachees/${userId}`);
  };

  const handleSendMessage = (userId: string) => {
    if (userId) {
      dispatch(setIsLoadingChat(true));
      dispatch(openChat(userId));
    }
  };

  const handleMarkAsComplete = (userId: string) => {
    setSelectedCoacheeId(userId);
    setCompleteSurveyModalVisible(true);
  };

  const buttons = [
    {
      title: 'Mark as Complete',
      type: 'primary',
      handler: handleMarkAsComplete,
    },
    {
      title: 'Coachee Bio',
      type: 'primary',
      handler: handleOpenCoacheeBio,
    },
    {
      title: 'Send Message',
      type: 'primary-blue',
      handler: handleSendMessage,
      opensChat: true,
    },
  ];

  const isActiveCoacheesTab = (): boolean => {
    return activeNavItem.name === 'activeCoachees';
  };

  const {
    data: coachees,
    isFetching,
    error,
    refetch: refetchCoachees,
  } = useQuery(
    loggedUser && [getAssignedCoachees.QUERY_KEY, { active: isActiveCoacheesTab() }],
    ({ queryKey }: { queryKey: [string, { active: boolean }] }) => getAssignedCoachees(queryKey[1])
  );
  const {
    data: userProfile,
    isFetching: isFetchingUserProfile,
    refetch: refetchUserProfile,
  } = useQuery(
    loggedUser && [getProfile.QUERY_KEY, { userId: loggedUser.id }],
    ({ queryKey }: { queryKey: [string, { userId: string }] }) => getProfile(queryKey[1].userId, true)
  );

  const isCoachCommitmentPaused = (): boolean => {
    return get(userProfile, 'profilePayload.data.coach_commitment_paused', 'No').toLowerCase() === 'yes';
  };

  const handleSecondaryMenuClick = (sectionName: string) => {
    const navItem = find(menuItems, (item: { name: string }) => {
      return item.name === get(sectionName, 'key');
    });
    if (navItem) {
      setActiveNavItem(navItem);
    }
  };

  const handlePauseCoachCommitment = async (coachId: string) => {
    const status = isCoachCommitmentPaused() ? 'No' : 'Yes';

    await pauseCoachCommitment(coachId, status, refetchUserProfile);
  };

  const coacheesItems =
    coachees &&
    coachees.map((item: Coachee) => {
      const buttonsList = item.completedByCoach ? buttons.slice(1) : buttons;

      return (
        <SectionWrapper key={item.id}>
          <UserDetailsContainer userData={item} buttonsList={buttonsList} />
        </SectionWrapper>
      );
    });

  return (
    <>
      <SEO title="My Coachees" />
      <PageHeader
        title="My Coachees"
        breadcrumb={[
          <Link key="1" to="/myhsf">
            {tenantLabel} Dashboard
          </Link>,
          'My Coachees',
        ]}
        fullWidth
        isResponsive
        noShadow
      />
      <StyledContainer>
        {!isFetchingUserProfile && (
          <StyledButton
            type={isCoachCommitmentPaused() ? 'primary' : 'primary-blue'}
            onClick={() => handlePauseCoachCommitment(get(loggedUser, 'id', ''))}
          >
            {isCoachCommitmentPaused() ? 'Resume Coach Commitment' : 'Pause Coach Commitment'}
          </StyledButton>
        )}
      </StyledContainer>
      <StyledHorizontalMenu>
        <HorizontalSecondaryMenu activeItem={activeNavItem} handleClick={handleSecondaryMenuClick} items={menuItems} />
      </StyledHorizontalMenu>
      <InternalContainer>
        {!error && isFetching && <Loader />}
        {!error &&
          !isFetching &&
          (coacheesItems && coacheesItems.length > 0 ? coacheesItems : <NoCoachees active={isActiveCoacheesTab()} />)}
        {error && <StyledMessage>{error.message}</StyledMessage>}
      </InternalContainer>
      <CoacheeCompleteSurveyModal
        closeModal={() => {
          setCompleteSurveyModalVisible(false);
          setSelectedCoacheeId('');
          refetchCoachees();
        }}
        coacheeId={selectedCoacheeId}
        isVisible={completeSurveyModalVisible}
      />
      {isRealTimeChatEnabled && <Chat />}
    </>
  );
};

export default CoacheesList;
