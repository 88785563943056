import React, { ReactNode, Fragment } from 'react';
import { Redirect, RouteComponentProps } from '@reach/router';
import { useSelector } from 'react-redux';

import { isCoachMatchEnabled } from '../../../../state/selectors/tenant';

type CoachesMatchProps = RouteComponentProps & {
  children?: ReactNode;
};
const CoachesMatch = (props: CoachesMatchProps) => {
  const isCoachMatchEnable = useSelector(isCoachMatchEnabled());

  return isCoachMatchEnable ? <Fragment>{props.children}</Fragment> : <Redirect noThrow to={'/myhsf/dashboard'} />;
};

export default CoachesMatch;
