import React, { memo } from 'react';
import { Row, Col } from 'antd';
import styled from '@emotion/styled';
import DOMPurify from 'isomorphic-dompurify';
import { useDispatch, useSelector } from 'react-redux';
import DescriptionIcon from '@material-ui/icons/Description';

import theme from '../../../theme';
import { openChat, setIsLoadingChat } from '../../../state/actions/message';
import PageHeader, { PageHeaderProps } from '../../common/page-header';
import UserContent from '../../common/user-content';
import { IEventAttendee, StageType } from '../../../types/models/program-management';
import { Button } from '../../../components/index';
import { formatUniversityName } from '../../../utils/form';
import { getIsLoadingChat, getUserIdForChat } from '../../../state/selectors/message';

type AttendeeBioProps = {
  attendee?: IEventAttendee;
  breadcrumb?: PageHeaderProps['breadcrumb'];
};

const StyledRow = styled(Row)`
  margin-top: 16px;
`;

const StyledHr = styled.hr`
  margin: 24px 0;
  border: 0;
  height: 2px;
  background-color: #eee;
`;

const StyledUserContent = styled(UserContent)`
  background-color: transparent;
  padding: 16px 0 0 0;
  color: #000;
`;

const HeaderContainer = styled(Row)`
  display: flex;
  align-items: center;
  @media (max-width: ${theme.screenSm}) {
    flex-direction: column;
  }
`;

const ImageContainer = styled(Col)`
  display: flex;
  @media (max-width: ${theme.screenSm}) {
    align-items: center;
    justify-content: center;
  }
`;

const InfoContainer = styled(Col)`
  @media (max-width: ${theme.screenSm}) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  margin-right: 30px;
  overflow: hidden;
  border-radius: 50%;
  @media (max-width: ${theme.screenSm}) {
    flex-direction: column;
    margin: 0;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

const Name = styled.div`
  font-size: 32px;
  color: #000;
  font-weight: 700;
  line-height: 38px;
  margin-bottom: 8px;
`;

const Text = styled.div`
  font-size: 20px;
  color: #000;
  line-height: 32px;
  margin-bottom: 16px;
`;

const Bio = styled.div`
  font-size: 16px;
  color: #000;
  line-height: 24px;
`;

const CV = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
  cursor: pointer;
`;

const DocumentIcon = styled(DescriptionIcon)`
  margin-right: 10px;
  color: ${theme.colorsBlueGrey};
`;

const AttendeeBio = memo(function AttendeeBio({ attendee, breadcrumb }: AttendeeBioProps) {
  const {
    user,
    eventRoles,
    currentUserStageType,
    currentUniversityName,
    primaryMajor,
    userType,
    bio,
    stageType,
    documentUrl,
  } = attendee || {};

  const dispatch = useDispatch();
  const isLoadingChat = useSelector(getIsLoadingChat);
  const chatUserId = useSelector(getUserIdForChat);

  return (
    <>
      <PageHeader title="" noShadow breadcrumb={breadcrumb} />
      <StyledUserContent containerBackgroundColor="#fff" mobileFooterMargin={77}>
        {!!attendee && !!user && (
          <StyledRow>
            <Col xs={24} md={18}>
              <HeaderContainer>
                {user.imageUrl && (
                  <ImageContainer xs={24} sm={8} lg={6}>
                    <ImageWrapper>
                      <StyledImage src={user.imageUrl} />
                    </ImageWrapper>
                  </ImageContainer>
                )}
                <InfoContainer xs={24} sm={16} lg={18}>
                  <Name>
                    {user.firstName} {user.lastName}
                  </Name>
                  <Text>{eventRoles?.join(', ')}</Text>
                  {currentUserStageType === StageType.PRIVATE_EVENT_NON_APPLICANTS &&
                    stageType === StageType.PRIVATE_EVENT_APPLICANTS &&
                    primaryMajor && <Text>{primaryMajor}</Text>}
                  {stageType === StageType.PRIVATE_EVENT_APPLICANTS && currentUniversityName && (
                    <Text>{formatUniversityName(currentUniversityName)}</Text>
                  )}
                  {userType === 'registeredUser' && (
                    <Button
                      onClick={() => {
                        dispatch(setIsLoadingChat(true));
                        dispatch(openChat(user.id));
                      }}
                      type="primary-blue"
                      loading={isLoadingChat && chatUserId === user.id}
                    >
                      Send Message
                    </Button>
                  )}
                </InfoContainer>
              </HeaderContainer>
              <StyledHr />
              {stageType === StageType.PRIVATE_EVENT_NON_APPLICANTS && bio && (
                <Bio dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(bio) }} />
              )}
              {currentUserStageType === StageType.PRIVATE_EVENT_NON_APPLICANTS &&
                stageType === StageType.PRIVATE_EVENT_APPLICANTS &&
                documentUrl && (
                  <CV>
                    <DocumentIcon />{' '}
                    <a href={documentUrl} rel="noopener noreferrer" target="_blank" download>
                      {documentUrl.split('/').pop()}
                    </a>
                  </CV>
                )}
            </Col>
          </StyledRow>
        )}
      </StyledUserContent>
    </>
  );
});

export default AttendeeBio;
