import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import { Link } from 'gatsby';

import MentorDashboard from './dashboard/mentor-dashboard';

import PageHeader from '../../../../components/common/page-header';
import SEO from '../../../../components/seo';
import { useQuery } from '../../../../hooks/use-query';
import * as api from '../../../../apis/users/mentorship';
import * as actions from '../../../../state/actions/myhsf/mentorship/mentor-bio/mentor-bio';
import { RootState } from '../../../../state/reducers';
import { tenantLabel } from '../../../../siteContent';
import Loader from '../../../../components/common/loader';

const mapState = ({ mentorBioState }: RootState) => ({
  mentor: mentorBioState.mentor,
});

const mapDispatch = {
  setMentor: actions.setMentorData,
};

const connector = connect(mapState, mapDispatch);

type MentorBioProps = ConnectedProps<typeof connector> & {
  userId: string;
};

const MentorBio: React.FC<RouteComponentProps<MentorBioProps>> = props => {
  const { setMentor } = props;

  const { data, isLoading } = useQuery(
    [
      api.getMentor.QUERY_KEY,
      {
        userId: props.userId,
      },
    ],
    () => api.getMentor({ userId: `${props.userId}` })
  );

  useEffect(() => {
    if (data && setMentor) {
      setMentor(data);
    }
  }, [data, setMentor]);

  const breadcrumbs: Array<React.ReactNode | string> = [
    <Link key="1" to="/myhsf">
      {tenantLabel} Dashboard
    </Link>,
    <Link key="2" to="/myhsf/my-mentors">
      {tenantLabel} Mentors
    </Link>,
    `${data?.firstName} ${data?.lastName}`,
  ];

  if (!props.mentor || isLoading) {
    return <Loader />;
  }

  return (
    <>
      <SEO title="Mentors" />
      <PageHeader title={''} breadcrumb={breadcrumbs} noShadow />
      <MentorDashboard />
    </>
  );
};

export default connector(MentorBio);
