import styled from '@emotion/styled';

import { BlockRow } from '../admin/common/common-styled-component';
import theme from '../../theme';

export const PageContainer = styled(BlockRow)`
  min-height: 100vh;
  margin: 0 auto;
  background-color: ${theme.colorsLightestGrey};
  width: 80%;
  padding: 24px;
  margin: auto;
`;
