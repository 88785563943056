import React from 'react';
import { css } from '@emotion/react';

import { Select } from '../../../../../../components/forms';
import { SelectProps } from '../../../../../../components/forms/select';

export const SelectCss = css({
  width: '90% !important',
  border: 'none',
  borderColor: 'transparent',
  '.ant-select-selection__choice__content': {
    width: '95%',
  },
});

const FilterSelect: React.FC<SelectProps> = props => {
  return <Select css={SelectCss} {...props} />;
};

export default FilterSelect;
