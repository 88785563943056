import React from 'react';
import { Card, Row } from 'antd';
import styled from '@emotion/styled';
import { navigate } from 'gatsby';
import moment from 'moment';

import { validateUrl } from './dashboard-tile';

import { setClickedLinkToApply } from '../../../apis/users/stages';
import theme from '../../../theme';
import {
  FormCompletedStatusBlock,
  FormInProgressStatusBlock,
  FormNotStartedStatusBlock,
} from '../../../components/admin/common/blocks';
import { FORM_STATUS_DICTIONARY } from '../../../components/admin/common/constants';
import { Button } from '../../../components';
import { JOB_CAMPAIGN_TYPE_LABELS } from '../../../constants';
import { CampaignType, StageType } from '../../../types/models/program-management';
import { IJobCampaignProgram } from '../../../types/models/career-center';
import { IUser } from '../../../types/models/auth';
import { FormStatus } from '../../../types/form';
import { formatDate } from '../../../utils/date';

type CareerCenterCardProps = {
  data: IJobCampaignProgram;
  userInfo: IUser | null;
};

const StyledCard = styled(Card)`
  height: 100%;
  .ant-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;
const Type = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: #6e6e6e;
  padding-bottom: 4px;
`;
const Title = styled.div`
  color: ${theme.colorsBlack};
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  padding-bottom: 4px;
`;
const Company = styled.div`
  color: ${theme.colorsBlack};
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 4px;
`;
const Location = styled.div`
  color: ${theme.colorsBlack};
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 16px;
`;
const Deadline = styled.div`
  color: ${theme.colorsBlack};
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  padding-bottom: 8px;
`;
const EndDate = styled.div`
  color: ${theme.colorsBlack};
  font-size: 18px;
  line-height: 28px;
  padding-bottom: 16px;
`;
const Link = styled.a`
  width: 100%;
  text-decoration: none;
  font-weight: 700;
`;
const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: auto;
  & > button {
    width: 90%;
  }
`;

const renderStatusTag = (text: FormStatus | undefined) => {
  let button;
  switch (text) {
    case FormStatus.NotStarted:
      button = (
        <FormNotStartedStatusBlock noMargin>{FORM_STATUS_DICTIONARY[FormStatus.NotStarted]}</FormNotStartedStatusBlock>
      );
      break;
    case FormStatus.InProgress:
      button = (
        <FormInProgressStatusBlock noMargin>{FORM_STATUS_DICTIONARY[FormStatus.InProgress]}</FormInProgressStatusBlock>
      );
      break;
    case FormStatus.Completed:
      button = (
        <FormCompletedStatusBlock noMargin>{FORM_STATUS_DICTIONARY[FormStatus.Completed]}</FormCompletedStatusBlock>
      );
      break;
  }

  return button;
};

const CareerCenterCard = ({ data, userInfo }: CareerCenterCardProps) => {
  const isStageTypeInterestForm: boolean = data.stage.stageType === StageType.INTEREST_FORM;
  const deadline: string = formatDate(data.stage.endDate, 'MMM DD, YYYY h:mm a Z', data.stage.timezone);
  const cta: string = isStageTypeInterestForm ? 'Learn More' : 'Apply';

  const handleApplyClick = () => {
    if (data?.stage.id && userInfo?.id) {
      setClickedLinkToApply(data?.stage.id, userInfo?.id);
    }
  };

  const handleLearnMoreClick = () => {
    navigate(`/myhsf/career-center/job-details/${data.stage?.userStageId}`);
  };

  const renderButton = () => {
    if (isStageTypeInterestForm) {
      return (
        <Button type="primary" onClick={handleLearnMoreClick}>
          {cta}
        </Button>
      );
    }

    return (
      <Button type="primary" onClick={handleApplyClick}>
        <Link
          href={data.stage?.urlToApply && validateUrl(data.stage?.urlToApply)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {cta}
        </Link>
      </Button>
    );
  };

  return (
    <StyledCard>
      <Row align="middle" justify="space-between">
        <Type>{JOB_CAMPAIGN_TYPE_LABELS[data?.additionalData.campaignType as CampaignType]}</Type>
        {isStageTypeInterestForm ? renderStatusTag(data.stage.formStatus) : null}
      </Row>
      <Title>{data.name}</Title>
      <Company>{data?.additionalData?.company?.company}</Company>
      <Location>{data?.additionalData?.location ? data.additionalData.location : '-'}</Location>
      <Deadline>Deadline ({moment(data?.stage?.endDate).diff(moment(), 'days')} days left)</Deadline>
      <EndDate>{deadline}</EndDate>
      <ButtonWrapper>{renderButton()}</ButtonWrapper>
    </StyledCard>
  );
};

export default CareerCenterCard;
