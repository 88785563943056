import { programShortName, tenantLabel } from '../../siteContent';

import CohortDashboardPage from '../../components/user/cohorts/cohort-dashboard';
import { Link } from 'gatsby';
import React from 'react';
import { RouteComponentProps } from '@reach/router';
import SEO from '../../components/seo';

const CohortDashboard = (_: RouteComponentProps) => {
  const description = `Welcome to your ${programShortName} Scholars directory, where you can learn more about your fellow scholars.  Use the filters below to browse through this list and connect with other scholars.`;

  return (
    <>
      <SEO title={`${programShortName} Scholars`} />
      <CohortDashboardPage
        title={`${programShortName} Scholars`}
        description={description}
        breadcrumb={[
          <Link key="1" to="/myhsf">
            {tenantLabel} Dashboard
          </Link>,
          `${programShortName} Scholars`,
        ]}
      />
    </>
  );
};

export default CohortDashboard;
