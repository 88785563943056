import styled from '@emotion/styled';
import { Row, Col, Form, Modal, Input, Select } from 'antd';
import { BlockRow } from '../../admin/common/common-styled-component';
import React, { useState } from 'react';

import { Button } from '../../index';
import theme from '../../../theme';

const { Option } = Select;

const Container = styled.div`
  margin: 0 auto;
  max-width: ${theme.screenLgMax};
  padding: 0 1.0875rem 1.45rem;
  & .ant-select-selection__choice__content {
    padding-right: 10px;
  }
`;

const Caption = styled.div`
  color: ${theme.colorsBlack};
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MULTIPLE_SELECT_PLACEHOLDER = 'Select one or more';

type CohortFilterModalProps = {
  genderList: [];
  openFilter: boolean;
  onClose: () => void;
  getMoreFilters: (value: any) => void;
  clearFilters: () => void;
};

export const CohortFilterModal = ({
  genderList,
  openFilter,
  onClose,
  getMoreFilters,
  clearFilters,
}: CohortFilterModalProps) => {
  const [moreFilterQuery, setMoreFilterQuery] = useState<any>([]);

  const clearFilter = (): void => {
    setMoreFilterQuery([]);
    clearFilters();
  };

  const saveMoreFilters = () => {
    getMoreFilters(moreFilterQuery);
  };

  const handleHometownFilterOnChange = (event: any) => {
    if (!event.target.value) {
      const empty = moreFilterQuery.filter((item: any) => item.field !== 'hometown');
      setMoreFilterQuery(empty);

      return;
    }
    setMoreFilterQuery([...moreFilterQuery, { field: 'hometown', operator: 'like', value: event.target.value }]);
  };

  const handleGenderFilterOnChange = (value: string[]) => {
    if (!value.length) {
      const empty = moreFilterQuery.filter((item: any) => item.field !== 'sex');
      setMoreFilterQuery(empty);

      return;
    }
    setMoreFilterQuery([...moreFilterQuery, { field: 'sex', operator: 'in', value }]);
  };

  const Genders: JSX.Element[] = genderList?.map((item: any) => (
    <Option key={item.id} value={item.id}>
      {item.displayText}
    </Option>
  ));

  const selectValue = (field: string): [] => {
    return moreFilterQuery.reduce((prev: any, next: any) => {
      if (next.field === field) {
        prev = next.value;
      }
      return prev;
    }, []);
  };

  return (
    <Modal
      width={900}
      visible={openFilter}
      title="More Filters"
      closable={true}
      onCancel={onClose}
      footer={[
        <StyledDiv key="1">
          <Button key="clear" type="link" onClick={clearFilter}>
            Clear Filters
          </Button>
          <div>
            <Button key="cancel" type="default" onClick={onClose}>
              Cancel
            </Button>
            <Button key="apply" type="primary-blue" onClick={saveMoreFilters}>
              Apply Filters
            </Button>
          </div>
        </StyledDiv>,
      ]}
    >
      <Form name="basic" colon={false}>
        <Container>
          <Row>
            <Caption>Hometown</Caption>
            <Col span={24}>
              <Input
                size="large"
                placeholder="Search by Name"
                onChange={handleHometownFilterOnChange}
                value={selectValue('hometown')}
              />
            </Col>
          </Row>
          <BlockRow>
            <Caption>Gender</Caption>
            <Col>
              <Select
                value={selectValue('sex')}
                size="large"
                mode="multiple"
                optionFilterProp="children"
                placeholder={MULTIPLE_SELECT_PLACEHOLDER}
                onChange={handleGenderFilterOnChange}
                defaultActiveFirstOption={false}
              >
                {Genders}
              </Select>
            </Col>
          </BlockRow>
        </Container>
      </Form>
    </Modal>
  );
};
