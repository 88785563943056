import React from 'react';

import { Modal } from '../../../../components';
import SurveyModal from '../../survey-modal/survey-modal';
import { markMenteeDiscussionComplete } from '../../../../apis/users/mentorship';

type CompleteSurveyModalProps = {
  closeModal: () => void;
  menteeId: string;
  milestoneId: string;
  isVisible: boolean;
};

const surveyQuestions = [
  {
    value: 'Rate the level of engagement of your mentee',
    type: 'rate',
    for: 'engagement',
  },
  {
    value: 'Rate the responsiveness of your mentee during this milestone',
    type: 'rate',
    for: 'responsiveness',
  },
  {
    value: 'Rate the usefulness of the subject matter and information covered in this milestone',
    type: 'rate',
    for: 'usefulness',
  },
  {
    value: 'Rate the strength of your relationship with your mentee',
    type: 'rate',
    for: 'relationshipStrength',
  },
  {
    value: 'Rate your overall satisfaction with the HSF MentorMatch program',
    type: 'rate',
    for: 'overallSatisfaction',
  },
  {
    value: 'How likely are you to recommend the HSF MentorMatch program to a friend? ',
    type: 'select',
    for: 'recommend',
  },
];

const survey = {
  engagement: 0,
  responsiveness: 0,
  usefulness: 0,
  relationshipStrength: 0,
  overallSatisfaction: 0,
  recommend: null,
};

const MenteeCompleteMilestoneSurveyModal = ({
  closeModal,
  menteeId,
  milestoneId,
  isVisible,
}: CompleteSurveyModalProps) => {
  const handleCompleteSurveyOnOk = async (value: object) => {
    await markMenteeDiscussionComplete(menteeId, milestoneId, value).catch((err: { message: any }) => {
      Modal.error({
        title: 'Failed to submit survey',
        content: err.message,
      });
    });
    closeModal();
  };

  const handleCompleteSurveyOnCancel = () => {
    closeModal();
  };

  return (
    <SurveyModal
      questionsList={surveyQuestions}
      onOk={handleCompleteSurveyOnOk}
      onCancel={handleCompleteSurveyOnCancel}
      survey={survey}
      title="End of Milestone"
      visible={isVisible}
    />
  );
};

export default MenteeCompleteMilestoneSurveyModal;
