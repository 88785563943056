import React from 'react';

type IconCheckMarkBackgroundProps = {
  width?: string | number;
  height?: string | number;
  svgFill?: string;
  pathFill?: string;
};

const IconCheckMarkBackground = ({
  width = '48',
  height = '48',
  svgFill = 'none',
  pathFill = '#DFE4EA',
}: IconCheckMarkBackgroundProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill={svgFill} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6973 5.20057C19.0618 -1.73353 28.9382 -1.73352 33.3027 5.20057C34.7974 7.5753 37.0995 9.29323 39.7603 10.0195C47.5297 12.1401 50.5817 21.7877 45.5097 28.1939C43.7727 30.3878 42.8934 33.1674 43.0431 35.991C43.4803 44.2358 35.4902 50.1983 27.991 47.2234C25.4228 46.2046 22.5772 46.2046 20.009 47.2234C12.5098 50.1983 4.51966 44.2358 4.95689 35.991C5.10663 33.1675 4.2273 30.3878 2.4903 28.1939C-2.58168 21.7877 0.470267 12.1401 8.23967 10.0195C10.9005 9.29323 13.2026 7.5753 14.6973 5.20057Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default IconCheckMarkBackground;
