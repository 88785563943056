import { RouteComponentProps } from '@reach/router';
import React from 'react';

import SEO from '../../components/seo';
import DocumentBankContainer from '../../components/user/document-bank/document-bank';

const DocumentBank = (_: RouteComponentProps) => {
  return (
    <>
      <SEO title="Document Bank" />
      <DocumentBankContainer title="Document Bank" />
    </>
  );
};

export default DocumentBank;
