import React, { ReactNode } from 'react';
import { RouteComponentProps } from '@reach/router';

import SEO from '../../../components/seo';

const DiscernmentSurvey = ({ children }: RouteComponentProps & { children?: ReactNode }) => {
  return (
    <>
      <SEO title="Discernment Survey" />
      {children}
    </>
  );
};

export default DiscernmentSurvey;
