import {
  MentorsFilterAction,
  MentorsFilterTypes,
} from '../../../../../../types/myhsf/mentorship/manual-application/mentors-list/mentors-filter-types';
import { AppThunk } from '../../../../index';

export function handleJobTypeChange(value: string): MentorsFilterAction {
  return {
    type: MentorsFilterTypes.setJobTypeValue,
    payload: value,
  };
}

export function handleCollegeMajorAreaChange(value: string): MentorsFilterAction {
  return {
    type: MentorsFilterTypes.setCollegeMajorAreaValue,
    payload: value,
  };
}

export function handleSkillsChange(value: Array<string>): MentorsFilterAction {
  return {
    type: MentorsFilterTypes.setSkillsValue,
    payload: value,
  };
}

export function handleLocationChange(value: string): MentorsFilterAction {
  return {
    type: MentorsFilterTypes.setLocationValue,
    payload: value,
  };
}

export function handleSexChange(value: string): MentorsFilterAction {
  return {
    type: MentorsFilterTypes.setSexValue,
    payload: value,
  };
}

export function handleEducationChange(value: string): MentorsFilterAction {
  return {
    type: MentorsFilterTypes.setEducationValue,
    payload: value,
  };
}

export function handleProfessionalExperienceChange(value: string): MentorsFilterAction {
  return {
    type: MentorsFilterTypes.setProfessionalExperienceValue,
    payload: value,
  };
}

export function toggleVisibleAdvancedFiltersAction(): AppThunk {
  return (dispatch, getState) => {
    const { visibleAdvancedFilters } = getState().manualMentorsMatchFilterState;

    dispatch({
      type: MentorsFilterTypes.setVisibleAdvancedFilters,
      payload: !visibleAdvancedFilters,
    });
  };
}
