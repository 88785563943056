import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Link } from 'gatsby';

import MenteeDashboard from './dashboard/mentee-dashboard';

import Loader from '../../../../components/common/loader';
import PageHeader from '../../../../components/common/page-header';
import SEO from '../../../../components/seo';
import { useQuery } from '../../../../hooks/use-query';
import { tenantLabel } from '../../../../siteContent';
import * as api from '../../../../apis/users/mentorship';

type MenteeBioProps = {
  userId: string;
};

const MenteeBio: React.FC<RouteComponentProps<MenteeBioProps>> = props => {
  const { data: mentee, isLoading } = useQuery(
    [
      api.getMentee.QUERY_KEY,
      {
        userId: props.userId,
      },
    ],
    () => api.getMentee({ userId: `${props.userId}` })
  );

  const breadcrumbs: Array<React.ReactNode | string> = [
    <Link key="1" to="/myhsf">
      {tenantLabel} Dashboard
    </Link>,
    <Link key="2" to="/myhsf/my-mentees">
      {tenantLabel} Mentees
    </Link>,
    `${mentee?.firstName} ${mentee?.lastName} Bio`,
  ];

  if (!mentee || isLoading) {
    return <Loader />;
  }

  return (
    <>
      <SEO title={`${mentee?.firstName} ${mentee?.lastName}`} />
      <PageHeader title={''} breadcrumb={breadcrumbs} noShadow />
      <MenteeDashboard mentee={mentee} />
    </>
  );
};

export default MenteeBio;
