import React, { useState } from 'react';
import styled from '@emotion/styled';
import { includes } from 'lodash';

import { Tooltip } from '../../../../components';
import timeManagement from '../../../../resources/images/myhsf/time-management.svg';
import writingResumeCoverLetter from '../../../../resources/images/myhsf/writing-resume-cover-letter.svg';
import negotiationDecisionMaking from '../../../../resources/images/myhsf/negotiation-decision-making.svg';
import researchSkills from '../../../../resources/images/myhsf/research-skills.svg';
import interviewingForInternshipJob from '../../../../resources/images/myhsf/successfully-interviewing.svg';

export const badgeList = [
  {
    icon: timeManagement,
    title: 'Time management',
    name: 'Time management',
  },
  {
    icon: writingResumeCoverLetter,
    title: 'Writing a resume & cover letter',
    name: 'Writing a resume & cover letter',
  },
  {
    icon: negotiationDecisionMaking,
    title: 'Negotiation & Decision making',
    name: 'Negotiation & Decision making',
  },
  {
    icon: researchSkills,
    title: 'Research Skills',
    name: 'Exploring new opportunities and experiences (Research Skills)',
  },
  {
    icon: interviewingForInternshipJob,
    title: 'Interviewing for Internship/Job',
    name: 'Successfully interviewing for an internship or job',
  },
];

const SectionWrapper = styled.div`
  height: 280px;
  margin: 20px;
  background-color: white;
  position: relative;
`;

const TitleSection = styled.div`
  font-size: 18px;
  font-weight: bold;
  display: flex;
  margin: 30px;
  padding-top: 20px;
  height: 40px;
  color: black;
`;

const StyledDiv = styled.div`
  display: flex;
`;

const BadgeContainer = styled.div`
  display: inline;
  margin: 20px 30px;
  width: 96px;
  height: 156px;
`;

const BadgeTitle = styled.div`
  text-align: center;
  font-size: 14px;
  color: black;
`;

const TooltipIcon = styled(Tooltip)`
  right: 8px;
  bottom: 8px;
  background-color: #fff;
  border-radius: 50%;
  margin-top: 4px;
`;

const PartnerBorder = styled.hr`
  background-color: #f0f0f0;
  width: 95%;
  height: 1px;
  border-style: none;
  margin: auto;
`;

const SkillsContainer = ({ completedTracksList }: any) => {
  const [headerStyle, setHeaderStyle] = useState({ display: 'none' });

  const isBadgeVisible = (badgeName: string) => {
    if (includes(completedTracksList, badgeName) && headerStyle.display === 'none') {
      setHeaderStyle({ display: 'block' });
    }
    return includes(completedTracksList, badgeName);
  };

  return (
    <SectionWrapper style={headerStyle}>
      <TitleSection>
        {"Tools I've Earned"}
        <TooltipIcon title={'Tools are earned by completing a number of coresponding Milestones.'} />
      </TitleSection>
      <PartnerBorder />
      <StyledDiv>
        {badgeList &&
          badgeList.map((badge: any) => {
            return (
              isBadgeVisible(badge.name) && (
                <BadgeContainer key={badge.title}>
                  <img src={badge.icon} alt="icon" />
                  <BadgeTitle>{badge.title}</BadgeTitle>
                </BadgeContainer>
              )
            );
          })}
      </StyledDiv>
    </SectionWrapper>
  );
};

export default SkillsContainer;
