import { FormComponentType, IPageSchema, ISectionSchema, ValidationRuleType } from '../../../types/form';
import { RoleType } from '../../../types/models/auth';
import { programShortName } from '../../../siteContent';

const skillsPage = {
  type: 'page',
  title: 'Skill Ratings',
  elements: [
    {
      id: '1vpagchb1l6',
      name: 'perceived_strength',
      type: FormComponentType.Panel,
      title:
        'Please rank your perceived strength (on a 1-5 with 1=low and 5=high) on the following areas of knowledge/skills:',
      elements: [
        {
          id: '2rknpmqdlaa',
          name: 'life_long_learner',
          type: FormComponentType.Dropdown,
          title: 'Being a life-long learner',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
          validation: {
            type: 'string',
            rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
          },
          titlePath:
            'Please rank your perceived strength (on a 1-5 with 1=low and 5=high) on the following areas of knowledge/skills:-|-Being a life-long learner',
        },
        {
          id: '1d0lufhkpdi',
          name: 'building_relationships',
          type: FormComponentType.Dropdown,
          title: 'Building relationships',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
          validation: {
            type: 'string',
            rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
          },
          titlePath:
            'Please rank your perceived strength (on a 1-5 with 1=low and 5=high) on the following areas of knowledge/skills:-|-Building relationships',
        },
        {
          id: '1deme264v25',
          name: 'career_planning',
          type: FormComponentType.Dropdown,
          title: 'Career planning',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
          validation: {
            type: 'string',
            rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
          },
          titlePath:
            'Please rank your perceived strength (on a 1-5 with 1=low and 5=high) on the following areas of knowledge/skills:-|-Career planning',
        },
        {
          id: '1v850leqi93',
          name: 'decision_making',
          type: FormComponentType.Dropdown,
          title: 'Decision making',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
          validation: {
            type: 'string',
            rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
          },
          titlePath:
            'Please rank your perceived strength (on a 1-5 with 1=low and 5=high) on the following areas of knowledge/skills:-|-Decision making',
        },
        {
          id: '5qeun63nhas',
          name: 'delivering_results',
          type: FormComponentType.Dropdown,
          title: 'Delivering results',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
          validation: {
            type: 'string',
            rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
          },
          titlePath:
            'Please rank your perceived strength (on a 1-5 with 1=low and 5=high) on the following areas of knowledge/skills:-|-Delivering results',
        },
        {
          id: '23809wcj58n',
          name: 'oportunities_experiences',
          type: FormComponentType.Dropdown,
          title: 'Exploring new opportunities and experiences',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
          validation: {
            type: 'string',
            rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
          },
          titlePath:
            'Please rank your perceived strength (on a 1-5 with 1=low and 5=high) on the following areas of knowledge/skills:-|-Exploring new opportunities and experiences',
        },
        {
          id: '1d99ts1b6g1',
          name: 'goal_setting',
          type: FormComponentType.Dropdown,
          title: 'Goal setting',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
          validation: {
            type: 'string',
            rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
          },
          titlePath:
            'Please rank your perceived strength (on a 1-5 with 1=low and 5=high) on the following areas of knowledge/skills:-|-Goal setting',
        },
        {
          id: '22uc0nvrrro',
          name: 'leadership',
          type: FormComponentType.Dropdown,
          title: 'Leadership',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
          validation: {
            type: 'string',
            rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
          },
          titlePath:
            'Please rank your perceived strength (on a 1-5 with 1=low and 5=high) on the following areas of knowledge/skills:-|-Leadership',
        },
        {
          id: 'dh5df31pleg',
          name: 'personal_brand',
          type: FormComponentType.Dropdown,
          title: 'Managing your personal brand',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
          validation: {
            type: 'string',
            rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
          },
          titlePath:
            'Please rank your perceived strength (on a 1-5 with 1=low and 5=high) on the following areas of knowledge/skills:-|-Managing your personal brand',
        },
        {
          id: 'j4vppuhwmi',
          name: 'paying_it_forward_and_paying_it_back',
          type: FormComponentType.Dropdown,
          title: 'Paying it forward and paying it back',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
          validation: {
            type: 'string',
            rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
          },
          titlePath:
            'Please rank your perceived strength (on a 1-5 with 1=low and 5=high) on the following areas of knowledge/skills:-|-Paying it forward and paying it back',
        },
        {
          id: '7jj739t70uo',
          name: 'personal_finances',
          type: FormComponentType.Dropdown,
          title: 'Personal finances and financial planning',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
          validation: {
            type: 'string',
            rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
          },
          titlePath:
            'Please rank your perceived strength (on a 1-5 with 1=low and 5=high) on the following areas of knowledge/skills:-|-Personal finances and financial planning',
        },
        {
          id: '8dz3ryqs56w',
          name: 'personall_wellness',
          type: FormComponentType.Dropdown,
          title: 'Personal wellness (mental health, exercising, nutrition, etc.)',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
          validation: {
            type: 'string',
            rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
          },
          titlePath:
            'Please rank your perceived strength (on a 1-5 with 1=low and 5=high) on the following areas of knowledge/skills:-|-Personal wellness (mental health, exercising, nutrition, etc.)',
        },
        {
          id: '1eu5jioe5tz',
          name: 'graduate_school_preparing',
          type: FormComponentType.Dropdown,
          title: 'Preparing for graduate school',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
          validation: {
            type: 'string',
            rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
          },
          titlePath:
            'Please rank your perceived strength (on a 1-5 with 1=low and 5=high) on the following areas of knowledge/skills:-|-Preparing for graduate school',
        },
        {
          id: '1vf3uw6zqax',
          name: 'professional_development',
          type: FormComponentType.Dropdown,
          title: 'Professional development',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
          validation: {
            type: 'string',
            rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
          },
          titlePath:
            'Please rank your perceived strength (on a 1-5 with 1=low and 5=high) on the following areas of knowledge/skills:-|-Professional development',
        },
        {
          id: 'dajco0efuso',
          name: 'professional_networking',
          type: FormComponentType.Dropdown,
          title: 'Professional networking',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
          validation: {
            type: 'string',
            rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
          },
          titlePath:
            'Please rank your perceived strength (on a 1-5 with 1=low and 5=high) on the following areas of knowledge/skills:-|-Professional networking',
        },
        {
          id: 'aita14pfg7s',
          name: 'self_assessment',
          type: FormComponentType.Dropdown,
          title: 'Self-assessment',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
          validation: {
            type: 'string',
            rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
          },
          titlePath:
            'Please rank your perceived strength (on a 1-5 with 1=low and 5=high) on the following areas of knowledge/skills:-|-Personal wellness (mental health, exercising, nutrition, etc.)',
        },
        {
          id: 'b7ulsfdxoo0',
          name: 'intership_interviewing',
          type: FormComponentType.Dropdown,
          title: 'Successfully interviewing for an internship or job',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
          validation: {
            type: 'string',
            rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
          },
          titlePath:
            'Please rank your perceived strength (on a 1-5 with 1=low and 5=high) on the following areas of knowledge/skills:-|-Successfully interviewing for an internship or job',
        },
        {
          id: 'cylkp2p5d1c',
          name: 'time_management',
          type: FormComponentType.Dropdown,
          title: 'Time management',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
          validation: {
            type: 'string',
            rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
          },
          titlePath:
            'Please rank your perceived strength (on a 1-5 with 1=low and 5=high) on the following areas of knowledge/skills:-|-Time management',
        },
        {
          id: '2ltwblxt7v4',
          name: 'tracking_and_documenting_our_accomplishments',
          type: FormComponentType.Dropdown,
          title: 'Tracking and documenting our accomplishments',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
          validation: {
            type: 'string',
            rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
          },
          titlePath:
            'Please rank your perceived strength (on a 1-5 with 1=low and 5=high) on the following areas of knowledge/skills:-|-Tracking and documenting our accomplishments',
        },
        {
          id: '1s946gswg98',
          name: 'resume_writting',
          type: FormComponentType.Dropdown,
          title: 'Writing a resume and cover letter',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ],
          validation: {
            type: 'string',
            rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
          },
          titlePath:
            'Please rank your perceived strength (on a 1-5 with 1=low and 5=high) on the following areas of knowledge/skills:-|-Writing a resume and cover letter',
        },
      ],
      repeatable: false,
      isOrderedList: false,
    },
  ],
};

const coachCommitmentElement = {
  id: 'Er3Ffwfp94',
  name: 'coach_commitment_paused',
  type: FormComponentType.Radiogroup,
  title: 'Coach Commitment Status',
  populateFromProfile: true,
  syncToProfile: true,
  profileSyncField: 'coach_commitment_paused',
  validation: {
    type: 'string',
    rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
  },
  options: [
    {
      label: 'Paused',
      value: 'Yes',
    },
    {
      label: 'Active',
      value: 'No',
    },
  ],
  hasOtherOption: false,
  titlePath: 'Coach Commitment Status',
};

const getMentorshipActorsCountElement = (role: RoleType) => {
  let fieldId: string;
  let fieldName: string;
  let fieldTitle: string;
  let titlePath: string;

  switch (role) {
    case RoleType.MENTEE: {
      fieldId = 'ac947hs9d9153415';
      fieldName = 'mentors_count';
      fieldTitle = 'How many mentors would you like to have through MentorMatch? (Min of 1 – Max of 3)';
      titlePath = 'How many mentors would you like to have through MentorMatch? (Min of 1 – Max of 3)';
      break;
    }
    default: {
      fieldId = 'ac947hgdas9153415';
      fieldName = 'mentees_count';
      fieldTitle = 'How many mentees are you able to mentor?';
      titlePath = 'How many mentees are you able to mentor?';
      break;
    }
  }

  return {
    id: fieldId,
    name: fieldName,
    type: FormComponentType.Radiogroup,
    title: fieldTitle,
    options: [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
    ],
    validation: {
      type: 'string',
      rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
    },
    titlePath: titlePath,
  };
};

const getMentorshipExplanationPage = (role: RoleType) => {
  let fieldId: string;
  let fieldName: string;
  let fieldTitle: string;
  let pageTitle: string;
  let titlePath: string;

  switch (role) {
    case RoleType.COACH: {
      pageTitle = `Why do you want to be an ${programShortName} Career Coach?`;
      fieldId = 'Qr5bnMago5';
      fieldName = 'coachship_reason';
      fieldTitle = `Why do you want to be an ${programShortName} Career Coach? (max 250 words)`;
      titlePath = `Why do you want to be an ${programShortName} Career Coach? (max 250 words)`;
      break;
    }
    case RoleType.MENTEE: {
      pageTitle = 'Why do you want a mentor?';
      fieldId = 'Qr5bnMago5';
      fieldName = 'mentorship_reason';
      fieldTitle = 'Why do you want a mentor? (max 250 word)';
      titlePath = 'Why do you want a mentor? (max 250 word)';
      break;
    }
    default: {
      pageTitle = 'Why Mentor?';
      fieldId = '2239saja5a6';
      fieldName = 'mentorship_reason';
      fieldTitle = 'Why do you want to be a mentor? (max 250 word)';
      titlePath = 'Why do you want to be a mentor? (max 250 word)';
      break;
    }
  }

  const elements = [
    {
      id: fieldId,
      name: fieldName,
      type: FormComponentType.RichTextEditor,
      title: fieldTitle,
      validation: {
        type: 'string',
        rules: [{ type: ValidationRuleType.REQUIRED, params: ['This is a required field.'] }],
      },
      titlePath: titlePath,
    },
  ];

  if (role === RoleType.COACH) {
    elements.push(coachCommitmentElement);
  } else {
    elements.push(getMentorshipActorsCountElement(role));
  }

  return {
    type: 'page',
    title: pageTitle,
    elements: elements,
  } as IPageSchema;
};

export const getMentorshipSection = (roles: RoleType[]) => {
  const pages: Array<IPageSchema> = [];

  if (roles.includes(RoleType.COACH)) {
    pages.push(getMentorshipExplanationPage(RoleType.COACH));
  }

  if (roles.includes(RoleType.MENTOR)) {
    pages.push(getMentorshipExplanationPage(RoleType.MENTOR));
  }

  if (roles.includes(RoleType.MENTEE)) {
    pages.push(getMentorshipExplanationPage(RoleType.MENTEE));
  }

  pages.push(skillsPage);

  return {
    type: 'section',
    pages,
    title: 'MentorMatch & Career Coach',
  } as ISectionSchema;
};
