import styled from '@emotion/styled';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Divider } from 'antd';
import { Avatar } from 'antd5';

import { openChat, setIsLoadingChat } from '../../../state/actions/message';
import { Button } from '../../../components';
import { getInitials } from '../../../utils/users';
import { getIsLoadingChat, getUserIdForChat } from '../../../state/selectors/message';

const { Meta } = Card;

const CardRow = styled.div`
  margin: 10px 0;
`;

const StyledTextDiv = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ButtonWrapper = styled('div')`
  & > button {
    width: 100%;
  }
`;

const InitialsAvatar = styled('div')`
  background: #074078;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: white;
  font-family: PT Sans, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
`;

const ScholarCard = ({ data }: any) => {
  const dispatch = useDispatch();
  const isLoadingChat = useSelector(getIsLoadingChat);
  const chatUserId = useSelector(getUserIdForChat);

  return (
    <>
      <Card>
        <Meta
          title={<b>{`${data.firstName} ${data.lastName}`}</b>}
          description={data.year}
          avatar={data.imageUrl ? <Avatar src={data.imageUrl} /> : <InitialsAvatar>{getInitials(data)}</InitialsAvatar>}
        />
        <Divider />
        <CardRow>
          <StyledTextDiv>
            <b>School: </b>
            {data.school}
          </StyledTextDiv>
        </CardRow>
        <CardRow>
          <StyledTextDiv>
            <b>Hometown: </b>
            {data.hometown}
          </StyledTextDiv>
        </CardRow>
        <ButtonWrapper>
          <Button
            key="cancel"
            type="default"
            onClick={() => {
              dispatch(setIsLoadingChat(true));
              dispatch(openChat(data.id));
            }}
            loading={isLoadingChat && chatUserId === data.id}
          >
            Send Message
          </Button>
        </ButtonWrapper>
      </Card>
    </>
  );
};

export default ScholarCard;
