import React, { useCallback, useState, memo } from 'react';
import styled from '@emotion/styled';
import { Row, Col } from 'antd';
import produce from 'immer';

import PageHeader, { PageHeaderProps, PageHeaderButton } from '../../common/page-header';
import UserContent from '../../common/user-content';
import { FormItem, Input, Select, FormElementChangeEvent, TextArea } from '../../forms';
import { ICreateMessageFormData } from '../../../types/models/message-center';
import useBeforeUnload from '../../../hooks/use-before-unload';
import theme from '../../../theme';
import { MessageItemType } from '../../../components/admin/message-center/message-type-dropdown';
import { useMessageTypesQuery } from '../../../apis/admin/message-center';

type MessageCenterCreateMessageProps = {
  title: PageHeaderProps['title'];
  breadcrumb?: PageHeaderProps['breadcrumb'];
  onSend: (formData: ICreateMessageFormData) => void;
  onCancel: () => void;
};

const StyledUserContent = styled(UserContent)`
  padding-top: 58px;
  @media (max-width: ${theme.screenLgMax}) {
    padding-top: 16px;
  }
`;

export const SEND_BUTTON_DATA_TEST_ID = 'send-button';
export const CANCEL_BUTTON_DATA_TEST_ID = 'cancel-button';
export const MESSAGE_SUBJECT_DATA_TEST_ID = 'message-subject';
export const MESSAGE_BODY_DATA_TEST_ID = 'message-body';

const MessageCenterCreateMessage = memo(function MessageCenterCreateMessage({
  title,
  breadcrumb,
  onSend,
  onCancel,
}: MessageCenterCreateMessageProps) {
  const [formData, setFormData] = useState<Partial<ICreateMessageFormData>>({
    messageType: undefined,
    subject: '',
    body: '',
  });
  const isDirty = Object.keys(formData).length > 0;
  useBeforeUnload(isDirty);

  const { data: messageTypeOptions, isLoading: loadingOptions } = useMessageTypesQuery();

  const MESSAGE_TYPE_OPTIONS = messageTypeOptions?.types.items.map((value: MessageItemType) => ({
    value: value.id,
    label: value.title,
  }));

  const validate = useCallback(() => {
    return Object.keys(formData).every(key => {
      const value: any = formData[key as keyof ICreateMessageFormData];
      const cleanValue = typeof value === 'string' ? value.trim() : value;
      return !!cleanValue;
    });
  }, [formData]);

  const handleSend = useCallback(async () => {
    if (!validate()) {
      return;
    }

    onSend(formData as ICreateMessageFormData);
  }, [validate, onSend, formData]);

  const handleChange = useCallback(
    async (event: FormElementChangeEvent) => {
      const { name, value } = event;
      if (!name) {
        return;
      }
      const nextFormData = produce(formData, (draft: ICreateMessageFormData) => {
        draft[name as keyof ICreateMessageFormData] = value;
      });
      setFormData(nextFormData);
    },
    [formData]
  );

  return (
    <>
      <PageHeader
        title={title}
        breadcrumb={breadcrumb}
        actions={[
          <Row key="1" gutter={[16, 0]} align="middle" justify="end">
            <Col xs={12}>
              <PageHeaderButton block data-testid={CANCEL_BUTTON_DATA_TEST_ID} type="primary" onClick={onCancel}>
                Cancel
              </PageHeaderButton>
            </Col>
            <Col xs={12}>
              <PageHeaderButton
                block
                data-testid={SEND_BUTTON_DATA_TEST_ID}
                type="primary-blue"
                onClick={handleSend}
                disabled={!validate()}
              >
                Send
              </PageHeaderButton>
            </Col>
          </Row>,
        ]}
      />
      <StyledUserContent mobileFooterMargin={77}>
        <FormItem label="Message Type">
          <Select
            aria-required
            name="messageType"
            loading={loadingOptions}
            value={formData.messageType}
            options={MESSAGE_TYPE_OPTIONS}
            placeholder="Select"
            onChange={handleChange}
          />
        </FormItem>
        <FormItem label="Subject">
          <Input
            data-testid={MESSAGE_SUBJECT_DATA_TEST_ID}
            aria-required
            name="subject"
            value={formData.subject}
            maxLength={200}
            onChange={handleChange}
          />
        </FormItem>
        <FormItem label="Body">
          <TextArea
            data-testid={MESSAGE_BODY_DATA_TEST_ID}
            aria-required
            name="body"
            value={formData.body}
            autoSize={{ minRows: 6, maxRows: 6 }}
            maxLength={2000}
            onChange={handleChange}
          />
        </FormItem>
      </StyledUserContent>
    </>
  );
});

export default MessageCenterCreateMessage;
