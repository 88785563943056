import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../../../../../../components';
import { MenteeBio } from '../../../../../../types/models/mentorship';
import UserBioHeader from '../../../../../../components/user/user-bio/user-bio-header';
import { openChat, setIsLoadingChat } from '../../../../../../state/actions/message';
import { formatUniversity } from '../../../../mentorship/common';
import { getIsLoadingChat, getUserIdForChat } from '../../../../../../state/selectors/message';

type MenteeInfoProps = {
  mentee: MenteeBio;
};

const MenteeInfo: React.FC<MenteeInfoProps> = (props) => {
  const dispatch = useDispatch();
  const isLoadingChat = useSelector(getIsLoadingChat);
  const chatUserId = useSelector(getUserIdForChat);

  const sendMessage = () => {
    dispatch(setIsLoadingChat(true));
    dispatch(openChat(props.mentee.id));
  };

  const location = [props.mentee.city, props.mentee.state]
    .filter((v) => v !== null && v !== undefined && v !== '')
    .join(', ');

  const menteeInfo = [
    {
      label: 'Location',
      value: location,
    },
    {
      label: 'Current University',
      value: formatUniversity(props.mentee.university),
    },
    {
      label: 'Current Class Level',
      value: props.mentee.classLevel,
    },
    {
      label: 'High School',
      value: formatUniversity(props.mentee.highSchool),
    },
    {
      label: 'Undergraduate Major',
      value: props.mentee.bachelorMajors?.join(', '),
    },
  ];

  return (
    <UserBioHeader
      title={`${props.mentee.firstName} ${props.mentee.lastName}`}
      image={props.mentee?.imageUrl}
      subtitle={props.mentee?.primaryMajor}
      userInfoRows={menteeInfo}
      button={
        <Button
          key={'send-message'}
          type={'primary-blue'}
          onClick={sendMessage}
          block
          loading={isLoadingChat && chatUserId === props.mentee?.id}
        >
          Send Message
        </Button>
      }
    />
  );
};

export default MenteeInfo;
