import React, { memo, useCallback } from 'react';
import styled from '@emotion/styled';
import { Form } from 'antd';

import { ISettings, UserSettingsPushStatus } from '../../../types/models/user-settings';
import theme from '../../../theme';
import { Switch, Tooltip } from '../..';

type AppNotificationsProp = {
  isUpdating?: boolean;
  messageCenterPush?: boolean;
  realTimeChatPush?: boolean;
  onSwitchChange: (sectionName: keyof ISettings, propertyName: string, status: string) => void;
};

const RightAlignedFormItemControl = styled.div`
  text-align: right;
  line-height: 24px;
`;

const ComponentTitle = styled.div`
  color: ${theme.colorsBlack};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin: 12px 0 20px;
  @media (max-width: ${theme.screenLgMax}) {
    width: 100%;
  }
`;

const StyledFormItem = styled(Form.Item)`
  display: flex;
  .ant-form-item-label {
    margin-right: 12px;

    span {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    @media (max-width: ${theme.screenXsMax}) {
      width: 83.33333333%;
    }
  }
  .ant-form-item-control-wrapper {
    @media (max-width: ${theme.screenXsMax}) {
      width: 16.66666667%;
    }
  }
`;

const AppNotifications = memo(function AppNotifications({
  isUpdating,
  messageCenterPush,
  realTimeChatPush,
  onSwitchChange,
}: AppNotificationsProp) {
  const handleSwitch = useCallback(
    (sectionName: keyof ISettings, propertyName: string) => (checked: boolean) => {
      onSwitchChange(
        sectionName,
        propertyName,
        checked ? UserSettingsPushStatus.ENABLED : UserSettingsPushStatus.DISABLED
      );
    },
    [onSwitchChange]
  );

  return (
    <>
      <ComponentTitle>App Notifications</ComponentTitle>
      <StyledFormItem
        label={<Tooltip title="Lorem ipsum dolor sit amet">Chat Notifications</Tooltip>}
        labelAlign="left"
        colon={false}
      >
        <RightAlignedFormItemControl>
          <Switch
            checked={realTimeChatPush}
            disabled={isUpdating}
            onChange={handleSwitch('push', 'realTimeChatPush')}
          />
        </RightAlignedFormItemControl>
      </StyledFormItem>
      <StyledFormItem
        label={<Tooltip title="Lorem ipsum dolor sit amet">Message Center Notifications</Tooltip>}
        labelAlign="left"
        colon={false}
      >
        <RightAlignedFormItemControl>
          <Switch
            checked={messageCenterPush}
            disabled={isUpdating}
            onChange={handleSwitch('push', 'messageCenterPush')}
          />
        </RightAlignedFormItemControl>
      </StyledFormItem>
    </>
  );
});

export default AppNotifications;
