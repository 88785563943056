import React from 'react';
import styled from '@emotion/styled';
import { Card } from 'antd';

import CollegeResourceSuggestEdit from './college-resource-edit';

import { ICollegeResourceData } from '../../../types/models/college-navigator';
import theme from '../../../theme';
import { formatPhoneNumber } from '../../../utils/common';

const CardContent = styled.div`
  color: ${theme.colorsBlack};
`;

const CollegeResourceCard = ({ collegeResource }: { collegeResource: ICollegeResourceData }) => {
  return (
    <Card
      title={<b>{collegeResource.functionRoleName}</b>}
      extra={<CollegeResourceSuggestEdit resource={collegeResource} />}
    >
      <CardContent>
        <b>Category: </b> {collegeResource.categoryName}
      </CardContent>
      <CardContent>
        <b>Contact Name: </b>
        {collegeResource.contactName}
      </CardContent>
      <CardContent>
        <b>Contact Title: </b>
        {collegeResource.contactTitle}
      </CardContent>
      <CardContent>
        <b>Office/Department Name: </b>
        {collegeResource.officeDepartmentName}
      </CardContent>
      <CardContent>
        <b>Email Address: </b>
        <a href={`mailto:${collegeResource.email}`} rel="noopener noreferrer">
          {collegeResource.email}
        </a>
      </CardContent>
      <CardContent>
        <b>Phone Number: </b>
        {formatPhoneNumber(collegeResource.phone || '')}
      </CardContent>
      <CardContent>
        <b>Website URL: </b>
        <a href={`${collegeResource.website}`} rel="noopener noreferrer" target="_blank">
          {collegeResource.website}
        </a>
      </CardContent>
      <CardContent>
        <b>Address: </b>
        {collegeResource.address}
      </CardContent>
    </Card>
  );
};

export default CollegeResourceCard;
