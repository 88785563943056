import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import MenteeInfo from './mentee-info/mentee-info';

import theme from '../../../../../theme';
import UserContent from '../../../../../components/common/user-content';
import { UserContentLayoutType } from '../../../../../types/layout';
import { Skills } from '../../common';
import { MenteeBio } from '../../../../../types/models/mentorship';
import UserBioContentCard from '../../../../../components/user/user-bio/user-bio-content-card';

const UserContentCss = css`
  padding-top: 30px;
  background-color: ${theme.colorsLightestGrey};
`;

const MenteeSkillRow = styled.div`
  display: list-item;
  list-style: disc inside none;
`;

type MenteeDashboardProps = {
  mentee: MenteeBio;
};

const MenteeDashboard: React.FC<MenteeDashboardProps> = props => {
  const menteeSkills = Skills.filter(skill => props.mentee.skills?.includes(skill.value)).map(skill => {
    return <MenteeSkillRow key={skill.value}>{skill.label}</MenteeSkillRow>;
  });

  return (
    <React.Fragment>
      <MenteeInfo mentee={props.mentee} />
      <UserContent type={UserContentLayoutType.DASHBOARD} css={UserContentCss}>
        {menteeSkills.length > 0 && <UserBioContentCard title={'What I Need Help With'} content={menteeSkills} />}
      </UserContent>
    </React.Fragment>
  );
};

export default MenteeDashboard;
