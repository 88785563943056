import React, { memo } from 'react';
import { Row } from 'antd';
import styled from '@emotion/styled';
import CheckIcon from '@material-ui/icons/Check';

import CheckMarkBackgroundIcon from './checkMarkBackground';
import { StyledHr } from './event-details';

import { IEventRoom } from '../../../types/models/program-management';
import theme from '../../../theme';
import { Button } from '../../../components';

const Name = styled.h4`
  color: #000;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;

const Description = styled.p`
  color: #000;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 8px;
`;

const StyledRow = styled(Row)`
  flex-wrap: nowrap;
  align-items: start;
`;

const IconsContainer = styled.div`
  position: relative;
  margin-right: 24px;
`;

const StyledCheckIcon = styled(CheckIcon)`
  color: #fff;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -40%);
`;

type RoomsListProps = {
  rooms: IEventRoom[];
  onEnterRoom: (room: IEventRoom, roomIndex: number) => () => Promise<void>;
};

const RoomsList = memo(function RoomsList({ rooms, onEnterRoom }: RoomsListProps) {
  return (
    <>
      {rooms.map((room, index) => {
        const isLast = index === (rooms.length || 0) - 1;
        return (
          <Row key={room.id}>
            <StyledRow style={{ marginBottom: isLast ? 0 : 12 }}>
              <IconsContainer>
                {room.isVisited ? (
                  <>
                    <StyledCheckIcon />
                    <CheckMarkBackgroundIcon pathFill={theme.colorsPrimaryBlue} />
                  </>
                ) : (
                  <CheckMarkBackgroundIcon />
                )}
              </IconsContainer>
              <div>
                <Name>{room.name}</Name>
                <Description>{room.description}</Description>
                <Button
                  style={{ marginBottom: isLast ? 0 : 14 }}
                  type="primary-blue"
                  onClick={onEnterRoom(room, index)}
                >
                  Enter Room
                </Button>
              </div>
            </StyledRow>
            {!isLast && <StyledHr />}
          </Row>
        );
      })}
    </>
  );
});

export default RoomsList;
