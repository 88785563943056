import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Link } from 'gatsby';
import { Spin } from 'antd5';
import styled from '@emotion/styled';

import SEO from '../../../components/seo';
import { useQuery } from '../../../hooks/use-query';
import AttendeeBio from '../../../components/user/events/attendee-bio';
import { getProgramImageURL } from '../../../apis/users/programs';
import { tenantLabel } from '../../../siteContent';
import { IEventAttendee } from '../../../types/models/program-management';
import { getEvent, getEventAttendee } from '../../../apis/users/events';

const SpinOverride = styled.div`
  height: 100%;
  .ant-spin {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }
  .ant-breadcrumb .ant-spin-nested-loading,
  .ant-breadcrumb .ant-spin-container {
    display: inline-block;
  }
`;

type EventAttendeeBioProps = {
  eventId: string;
  userId: string;
};

const EventAttendeeBioContainer = ({ eventId = '', userId = '' }: RouteComponentProps<EventAttendeeBioProps>) => {
  const [attendee, setAttendee] = useState<IEventAttendee>();
  const { isLoading: isEventLoading, isFetching: isEventFetching, data: event } = useQuery(getEvent.QUERY_KEY, () =>
    getEvent(eventId)
  );
  const { isLoading, isFetching } = useQuery(getEventAttendee.QUERY_KEY, () => getEventAttendee(eventId, userId), {
    onSuccess: async data => {
      data.user.imageUrl = await getProgramImageURL(data.user.imageId);
      setAttendee(data);
    },
  });

  const isReady = !isEventLoading && !isEventFetching && !isLoading && !isFetching;

  return (
    <SpinOverride>
      <SEO title="Event attendee bio" />
      <Spin spinning={!isReady}>
        <AttendeeBio
          breadcrumb={[
            <Link key="1" to="/myhsf/dashboard">
              {tenantLabel} Dashboard
            </Link>,
            <Link key="2" to="/myhsf/events/registered">
              Events
            </Link>,
            <Spin key="3" spinning={isEventLoading}>
              <Link to={`/myhsf/events/event/${eventId}`}>{event?.name || ''}</Link>
            </Spin>,
            <Link key="4" to={`/myhsf/events/event/${eventId}/directory`}>
              Event Directory
            </Link>,
            'Attendee Bio',
          ]}
          attendee={attendee}
        />
      </Spin>
    </SpinOverride>
  );
};

export default EventAttendeeBioContainer;
