import React, { useCallback, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useQuery } from 'react-query';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import { Spin } from 'antd5';
import { Link } from 'gatsby';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

import CareerCenterCard from './career-center-card';
import CareerCenterFilters from './career-center-filters';

import SEO from '../../../components/seo';
import theme from '../../../theme';
import { getUser } from '../../../state/selectors/auth';
import { getApplyJobs, getInterestJobs } from '../../../apis/users/career-center';
import { PageChangeEvent, Pagination } from '../../../components/table/pagination';
import PageHeader from '../../../components/common/page-header';
import UserContent from '../../../components/common/user-content';
import { programShortName, tenantLabel } from '../../../siteContent';
import { UserContentLayoutType } from '../../../types/layout';
import { IJobCampaignProgram } from '../../../types/models/career-center';
import { IUser } from '../../../types/models/auth';

type QueryParams = {
  offset: number;
  filter: {
    opportunity: string[];
    status: string[] | null;
  };
};

type DashboardListProps = {
  data: IJobCampaignProgram[];
  userInfo: IUser | null;
};

const StyledWhiteBackground = styled.div`
  background-color: ${theme.colorsWhite};
`;
const CareerCenterHeader = styled(Row)`
  width: 100%;
  margin: 0 auto;
  max-width: ${theme.screenXl};
  color: ${theme.colorsBlack};
  flex-direction: column;
  @media (max-width: ${theme.screenLgMax}) {
    padding: 0 24px;
    margin: 0;
    max-width: none;
  }
`;
const NotFound = styled.div`
  text-align: center;
  color: ${theme.colorsDisabledGreyText};
  font-size: 24px;
  margin-top: 30px;
  font-weight: bold;
`;
const ContentDashboardStyle = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-top: 16px;
  max-width: ${theme.screenXl};
  @media (max-width: ${theme.screenLgMax}) {
    padding: 0 24px;
    margin: 0;
    max-width: none;
  }
`;
const ListStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  padding: 12px 0;
  width: 100%;
`;
const HeaderTitle = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 36px;
  margin-top: 16px;
`;
const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin: 16px 0;
  max-width: 930px;
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  color: ${theme.colorsBlack};
`;
const StyledRow = styled(Row)`
  margin-top: 12px;
`;

const DashboardList = ({ data, userInfo }: DashboardListProps): JSX.Element => {
  const dashboardList: JSX.Element[] = data?.map((item: IJobCampaignProgram) => (
    <Col sm={24} md={12} lg={12} xl={8} key={item.stage?.id}>
      <CareerCenterCard data={item} userInfo={userInfo} />
    </Col>
  ));

  return <ListStyle>{dashboardList}</ListStyle>;
};

const description = `Explore opportunities just for ${programShortName} students`;
const defaultQueryParams: QueryParams = {
  offset: 0,
  filter: {
    opportunity: [],
    status: [],
  },
};

const MyCareerCenter = (_path: RouteComponentProps) => {
  const userInfo: IUser | null = useSelector(getUser);
  const [applyJobs, setApplyJobs] = useState<IJobCampaignProgram[]>([]);
  const [interestJobs, setInterestJobs] = useState<IJobCampaignProgram[]>([]);
  const [typeValue, setTypeValue] = useState<string[]>([]);
  const [statusValue, setStatusValue] = useState<string[]>([]);
  const [queryParamsApplyJobs, setQueryParamsApplyJobs] = useState<QueryParams>(defaultQueryParams);
  const [queryParamsInterestJobs, setQueryParamsInterestJobs] = useState<QueryParams>(defaultQueryParams);
  const [currPageApplyJobs, setCurrPageApplyJobs] = useState<number>(1);
  const [currPageInterestJobs, setCurrPageInterestJobs] = useState<number>(1);
  const [totalApplyJobs, setTotalApplyJobs] = useState<number>(0);
  const [totalInterestJobs, setTotalInterestJobs] = useState<number>(0);
  const [pageSizeApplyJobs] = useState<number>(3);
  const [pageSizeInterestJobs, setPageSizeInterestJobs] = useState<number>(6);

  const { isLoading: isApplyJobsLoading, isFetching: isApplyJobsFetching, refetch: refetchApplyJobs } = useQuery(
    [getApplyJobs.QUERY_KEY, { pageSizeApplyJobs, queryParamsApplyJobs }],
    () => getApplyJobs({ limit: pageSizeApplyJobs, ...queryParamsApplyJobs }),
    {
      onSuccess: async jobs => {
        if (!jobs.data.length) setPageSizeInterestJobs(9);

        if (!statusValue.length) {
          setApplyJobs(jobs.data);
          setTotalApplyJobs(jobs.totalCount);
        } else {
          setApplyJobs([]);
        }
      },
    }
  );

  const { isLoading: isInterestJobsLoading, isFetching: isInterestJobsFetching } = useQuery(
    [getInterestJobs.QUERY_KEY, { pageSizeInterestJobs, queryParamsInterestJobs }],
    () => getInterestJobs({ limit: pageSizeInterestJobs, ...queryParamsInterestJobs }),
    {
      onSuccess: async jobs => {
        setInterestJobs(jobs.data);
        setTotalInterestJobs(jobs.totalCount);
      },
    }
  );

  const debounceTypeFilter = useCallback(
    debounce((value: string[]) => {
      setQueryParamsApplyJobs((prev: QueryParams) => ({
        ...prev,
        offset: 0,
        filter: {
          opportunity: value,
          status: prev.filter.status,
        },
      }));

      setQueryParamsInterestJobs((prev: QueryParams) => ({
        ...prev,
        offset: 0,
        filter: {
          opportunity: value,
          status: prev.filter.status,
        },
      }));
    }, 1000),
    []
  );

  const handleTypeFilterOnChange = (value: string[]) => {
    setTypeValue(value);
    setCurrPageApplyJobs(1);
    setCurrPageInterestJobs(1);
    debounceTypeFilter(value);
  };

  const handleStatusFilterOnChange = (value: string[]) => {
    setStatusValue(value);
    setCurrPageInterestJobs(1);
    setCurrPageApplyJobs(1);

    value.length ? setApplyJobs([]) : refetchApplyJobs();

    setQueryParamsInterestJobs((prev: QueryParams) => ({
      ...prev,
      offset: 0,
      filter: {
        opportunity: prev.filter.opportunity,
        status: value,
      },
    }));
  };

  const onPageChangeApplyJobs = (event: PageChangeEvent) => {
    setCurrPageApplyJobs(event.current);
    setQueryParamsApplyJobs(prev => ({ ...prev, offset: event.offset }));
  };

  const onPageChangeInterestJobs = (event: PageChangeEvent) => {
    setCurrPageInterestJobs(event.current);
    setQueryParamsInterestJobs(prev => ({ ...prev, offset: event.offset }));
  };

  const renderPaginationApplyJobs = () => {
    return (
      <Pagination
        current={currPageApplyJobs}
        total={totalApplyJobs}
        pageSize={pageSizeApplyJobs}
        onChange={onPageChangeApplyJobs}
      />
    );
  };

  const renderPaginationInterestJobs = () => {
    return (
      <Pagination
        current={currPageInterestJobs}
        total={totalInterestJobs}
        pageSize={pageSizeInterestJobs}
        onChange={onPageChangeInterestJobs}
      />
    );
  };

  const isReady: boolean =
    !isApplyJobsLoading && !isInterestJobsLoading && !isApplyJobsFetching && !isInterestJobsFetching;

  const renderContent = () => {
    if (!isReady) {
      return null;
    }

    if (!applyJobs.length && !interestJobs.length && isReady) {
      return <NotFound>No Results Found</NotFound>;
    }

    return (
      <ContentDashboardStyle>
        {applyJobs.length ? (
          <>
            <StyledRow align="middle" justify="space-between">
              <Title>You&apos;ve Been Selected To Apply For</Title>
              {renderPaginationApplyJobs()}
            </StyledRow>
            <Row gutter={[32, 16]} style={{ marginBottom: '0' }}>
              <DashboardList data={applyJobs} userInfo={userInfo} />
            </Row>
          </>
        ) : null}
        {interestJobs.length ? (
          <>
            <StyledRow align="middle" justify="space-between">
              <Title>Opportunities You May Like</Title>
              {renderPaginationInterestJobs()}
            </StyledRow>
            <Row gutter={[32, 16]} style={{ marginBottom: '0' }}>
              <DashboardList data={interestJobs} userInfo={userInfo} />
            </Row>
          </>
        ) : null}
      </ContentDashboardStyle>
    );
  };

  return (
    <>
      <SEO title="Career Center" />
      <PageHeader
        title={null}
        noShadow
        breadcrumb={[
          <Link key="1" to="/myhsf/dashboard">
            {tenantLabel} Dashboard
          </Link>,
          'Career Center',
        ]}
      />
      <StyledWhiteBackground>
        <CareerCenterHeader>
          <HeaderTitle>Career Center</HeaderTitle>
          <Description>{description}</Description>
        </CareerCenterHeader>
        <CareerCenterFilters
          typeValue={typeValue}
          statusValue={statusValue}
          handleTypeFilterOnChange={handleTypeFilterOnChange}
          handleStatusFilterOnChange={handleStatusFilterOnChange}
        />
      </StyledWhiteBackground>
      <Spin spinning={!isReady}>
        <UserContent type={UserContentLayoutType.DASHBOARD}>{renderContent()}</UserContent>
      </Spin>
    </>
  );
};

export default MyCareerCenter;
