import { Breadcrumb, Col, Form, Row } from 'antd';
import React, { useCallback, useState } from 'react';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { isEmpty } from 'lodash';
import produce from 'immer';
import styled from '@emotion/styled';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import CategoriesFilter from './categories-filter';
import CollegeResourceCard from './college-resource-card';
import FunctionRoleFilter from './function-roles-filter';

import { ICollegeDetails, ICollegeResourceData } from '../../../types/models/college-navigator';
import { getCollege, getCollegeResources } from '../../../apis/users/college-navigator';
import { getUser, useRoles } from '../../../state/selectors/auth';
import { FormElementChangeEvent } from '../../forms';
import Loader from '../../../components/common/loader';
import { NavigationWrapper } from '../../../components/common/page-header';
import { UnauthorizedAccess } from '../../no-access-modal';
import { formatPhoneNumber } from '../../../utils/common';
import { generateBreadcrumb } from '../../../containers/myhsf/scholarship/common/utils';
import { getProfile } from '../../../apis/users/profile';
import { tenantLabel } from '../../../siteContent';
import theme from '../../../theme';
import { useResponsive } from '../../../hooks/use-responsive';

export interface ICollegeResourceFormData {
  collegeResourcesId: number;
  data: {
    functionRoleId: string;
    contactName: string;
    contactTitle: string;
    officeDepartmentName: string;
    email: string;
    phone: string;
    website: string;
    address: string;
  };
}

const ErrorMessage = styled.div`
  color: ${theme.colorsBlack};
  font-weight: bold;
  padding-bottom: 10px;
  font-size: 22px;
  text-align: center;
  margin-top: 32px;
  @media (max-width: ${theme.screenLgMax}) {
    width: 100%;
  }
`;

const CollegeNotFound = styled.div`
  color: ${theme.colorsBlack};
  font-weight: bold;
  padding-bottom: 10px;
  font-size: 22px;
  text-align: center;
  margin-top: 32px;
  @media (max-width: ${theme.screenLgMax}) {
    width: 100%;
  }
`;

const CollegeTitle = styled.div`
  color: ${theme.colorsBlack};
  font-weight: bold;
  padding: 10px 0;
  font-size: 22px;
  @media (max-width: ${theme.screenLgMax}) {
    width: 100%;
  }
`;

const CollegeSubTitle = styled.div`
  color: ${theme.colorsBlack};
`;

const SelectionFilter = styled.div`
  margin-top: 10px;
`;

const CollegeNavigatorDataNotAvailable = 'College Navigator information is not available';
const UserProfileUniversityNotSet = `To See College Navigator information, please update your current College / University in your ${tenantLabel} Profile`;
const PrimaryCampusResourceURL = 'Primary Campus Resource URL';

const CollegeNavigator = () => {
  const { xl } = useResponsive();
  const isMobile = !xl;
  const userInfo = useSelector(getUser);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedFunctionRoles, setSelectedFunctionRoles] = useState<string[]>([]);
  const [categoriesMap, setCategoriesMap] = useState<{ [index: string]: string }>({});
  const [functionRolesMap, setFunctionRolesMap] = useState<{ [index: string]: string }>({});
  const { isScholar } = useRoles();

  const {
    data: userProfile,
    isLoading: isLoadingUserProfile,
    error: userProfileError,
  } = useQuery(
    userInfo && [getProfile.QUERY_KEY, { userId: userInfo.id }],
    ({ queryKey }: { queryKey: [string, { userId: string }] }) => getProfile(queryKey[1].userId, true)
  );

  const {
    data: collegeProfile,
    isLoading: isLoadingCollegeProfile,
    error: collegeProfileError,
  } = useQuery(
    userProfile?.profilePayload?.data?.current_university_name && [
      getCollege.QUERY_KEY,
      { universityId: userProfile?.profilePayload?.data?.current_university_name },
    ],
    ({ queryKey }: { queryKey: [string, { universityId: string }] }) => getCollege(queryKey[1].universityId)
  );

  const {
    data: collegeResources,
    isLoading: isLoadingCollegeResources,
    error: collegeResourcesError,
  } = useQuery(
    userProfile?.profilePayload?.data?.current_university_name && [
      getCollegeResources.QUERY_KEY,
      { universityId: userProfile?.profilePayload?.data?.current_university_name },
    ],
    ({ queryKey }: { queryKey: [string, { universityId: string }] }) => getCollegeResources(queryKey[1].universityId)
  );

  const handleCategoriesFilterOnChange = useCallback(
    ({ value }: FormElementChangeEvent) => {
      setSelectedCategories(value);
    },
    [setSelectedCategories]
  );
  const handleCategoriesFilterOnLoad = useCallback(
    (categoriesMap) => {
      setCategoriesMap(categoriesMap);
    },
    [setCategoriesMap]
  );

  const handleFunctionRolesFilterOnChange = useCallback(
    ({ value }: FormElementChangeEvent) => {
      setSelectedFunctionRoles(value);
    },
    [setSelectedFunctionRoles]
  );

  const handleFunctionRolesFilterOnLoad = useCallback(
    (functionRolesMap) => {
      setFunctionRolesMap(functionRolesMap);
    },
    [setFunctionRolesMap]
  );

  const renderProfile = () => {
    if (!collegeProfile || !collegeResources) {
      return null;
    }

    const profileData = produce(collegeProfile, (draft) => {
      draft.website =
        collegeResources.find(
          (resource) => resource.category.description.toLowerCase() === PrimaryCampusResourceURL.toLowerCase()
        )?.website || draft.website;
    });
    return <CollegeProfile profile={profileData} />;
  };

  const renderFilters = () => {
    const filterByCategory = (
      <Form.Item label="Filter by Category">
        <CategoriesFilter onChange={handleCategoriesFilterOnChange} onLoad={handleCategoriesFilterOnLoad} />
      </Form.Item>
    );

    const filterByFunction = (
      <Form.Item label="Filter by Function">
        <FunctionRoleFilter onChange={handleFunctionRolesFilterOnChange} onLoad={handleFunctionRolesFilterOnLoad} />
      </Form.Item>
    );

    return (
      <SelectionFilter>
        <Row gutter={[16, 16]} justify="center" align="middle">
          {isMobile ? (
            <Col span={24}>
              {filterByCategory} {filterByFunction}
            </Col>
          ) : (
            <>
              <Col span={12}>{filterByCategory}</Col>
              <Col span={12}>{filterByFunction}</Col>
            </>
          )}
        </Row>
      </SelectionFilter>
    );
  };

  const renderList = () => {
    if (isLoadingCollegeResources || isEmpty(categoriesMap) || isEmpty(functionRolesMap)) {
      return <Loader />;
    }

    return (
      collegeResources && (
        <Row gutter={[16, 16]}>
          <CollegeResourceList
            data={collegeResources
              ?.filter((resource: ICollegeResourceData) => {
                if (!selectedCategories.length && !selectedFunctionRoles.length) {
                  return true;
                }
                if (selectedCategories.includes(resource.categoryId) && !selectedFunctionRoles.length) {
                  return true;
                }
                if (!selectedCategories.length && selectedFunctionRoles.includes(resource.functionRoleId)) {
                  return true;
                }
                if (
                  selectedCategories.includes(resource.categoryId) &&
                  selectedFunctionRoles.includes(resource.functionRoleId)
                ) {
                  return true;
                }
              })
              .map((resource) =>
                produce(resource, (resource) => {
                  // resource.categoryName = categoriesMap ? categoriesMap[resource.categoryId] : '';
                  resource.functionRoleName = functionRolesMap ? functionRolesMap[resource.functionRoleId] : '';
                })
              )
              .filter((resource) => resource.categoryName.toLowerCase() !== PrimaryCampusResourceURL.toLowerCase())}
          />
        </Row>
      )
    );
  };

  const renderBreadcrumb = () => {
    return (
      <NavigationWrapper>
        <Breadcrumb separator={<NavigateNext />}>
          {generateBreadcrumb({ [`${tenantLabel} Dashboard`]: '/myhsf/dashboard' }, { 'College Navigator': '' }).map(
            (item, index) => (
              <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
            )
          )}
        </Breadcrumb>
      </NavigationWrapper>
    );
  };

  const renderContent = () => {
    if (!isScholar) {
      return (
        <UnauthorizedAccess message="You do not have permission to access this feature. Please contact your System Administrator for more details." />
      );
    }
    if (isLoadingUserProfile || isLoadingCollegeProfile || isLoadingCollegeResources) {
      return <Loader />;
    }

    if (!userProfile?.profilePayload?.data?.current_university_name) {
      return <CollegeNotFound>{UserProfileUniversityNotSet}</CollegeNotFound>;
    }
    if (userProfileError || collegeProfileError || collegeResourcesError) {
      return <ErrorMessage>{CollegeNavigatorDataNotAvailable}</ErrorMessage>;
    }
    return (
      <>
        {renderBreadcrumb()}
        {renderProfile()}
        {renderFilters()}
        {renderList()}
      </>
    );
  };

  return (
    <StyledWhiteBackground>
      <StyledContainer>{renderContent()}</StyledContainer>
    </StyledWhiteBackground>
  );
};

export default CollegeNavigator;

const StyledWhiteBackground = styled.div`
  background-color: ${theme.colorsWhite};
`;

const StyledContainer = styled.div`
  max-width: ${theme.screenXl};
  margin: 0 auto;
  min-height: 33vh;

  padding: 0 25px;

  @media (min-width: ${theme.screenLg}) {
    padding: 0;
  }
`;

const setHttp = (link: string) => {
  if (link.search(/^http[s]?:\/\//) == -1) {
    link = 'http://' + link;
  }
  return link;
};

const CollegeProfile = ({ profile }: { profile: ICollegeDetails }) => (
  <>
    <CollegeTitle>{profile?.schoolName}</CollegeTitle>
    <CollegeSubTitle>{`${profile?.street}, ${profile?.city}, ${profile?.state} ${profile?.zipcode}`}</CollegeSubTitle>
    <CollegeSubTitle>{formatPhoneNumber(profile?.phone)}</CollegeSubTitle>
    {profile?.website && (
      <CollegeSubTitle>
        <a href={setHttp(profile.website)} rel="noopener noreferrer" target="_blank">
          {profile.website}
        </a>
      </CollegeSubTitle>
    )}
  </>
);

const CollegeResourceList = ({ data }: { data: ICollegeResourceData[] | undefined }) => {
  const { xl } = useResponsive();
  const isMobile = !xl;
  const collegeResources: JSX.Element[] | undefined = data?.map((resource) => (
    <Col span={isMobile ? 24 : 12} key={resource.id}>
      <CollegeResourceCard collegeResource={resource} />
    </Col>
  ));
  return (
    <Row gutter={[16, 16]} justify="center" align="middle">
      {collegeResources}
    </Row>
  );
};
