import React, { memo, useState } from 'react';
import { Modal, Row, List, Col } from 'antd';
import styled from '@emotion/styled';
import { navigate } from 'gatsby';

import { getTracksByCareer, startTrack } from '../../../apis/users/hsf-university';
import { ITrackDetails } from '../../../types/models/hsf-university';
import { DEFAULT_MODAL_WIDTH } from '../../../constants';
import { Button } from '../../../components';
import { useQuery } from '../../../hooks/use-query';

type ModalProps = {
  name: string;
  handleCancel: () => void;
};

const CreerTracksModal = memo(function CreerTracksModal({ name, handleCancel }: ModalProps) {
  const [data, setData] = useState<ITrackDetails[]>();

  const [loading, setLoading] = useState(true);

  const { refetch: refetchTracks } = useQuery([getTracksByCareer.QUERY_KEY], () => getTracksByCareer({ name }), {
    onSuccess: (data) => {
      setData(data);
      setLoading(false);
    },
    onError: (err) => {
      setLoading(false);
      console.error(err);
    },
    cacheTime: 0,
  });

  const ItemStyle = styled.div`
    color: black;
    font-size: 16px;
  `;

  const ColStartWrapper = styled(Col)`
    flex: 1;
    text-align: start;
  `;

  const ColEndWrapper = styled(Col)`
    flex: 1;
    text-align: end;
    .ant-btn {
      width: 130px;
    }
  `;

  const RowWrapper = styled(Row)`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
  `;

  const handleSelectTrack = (trackId: string) => {
    setLoading(true);
    startTrack(trackId)
      .then(() => {
        refetchTracks();
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const handleGoToCourse = (trackId: string) => {
    navigate(`/myhsf/tracks/${trackId}`);
  };

  return (
    <>
      <Modal
        width={DEFAULT_MODAL_WIDTH}
        title="Related Tracks"
        visible={true}
        centered
        closable={true}
        onCancel={() => {
          handleCancel && handleCancel();
        }}
        footer={[]}
      >
        <div>
          <List
            loading={loading}
            dataSource={data}
            renderItem={(item: ITrackDetails) => (
              <List.Item>
                <RowWrapper>
                  <ColStartWrapper>
                    <ItemStyle>{item.name}</ItemStyle>
                  </ColStartWrapper>
                  <ColEndWrapper>
                    {item.selected ? (
                      <Button type="primary-blue" onClick={() => handleGoToCourse(item.id)}>
                        Go to Track
                      </Button>
                    ) : (
                      <Button type="primary-blue" onClick={() => handleSelectTrack(item.id)}>
                        Add Track
                      </Button>
                    )}
                  </ColEndWrapper>
                </RowWrapper>
              </List.Item>
            )}
          />
        </div>
      </Modal>
    </>
  );
});

export default CreerTracksModal;
