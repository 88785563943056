import _ from 'lodash';
import React, { memo, useMemo } from 'react';
import { useQuery } from 'react-query';

import { getCollegeFunctionRoleList } from '../../../apis/users/college-navigator';
import { Select } from '../../../components/forms';

const FunctionRolesFilter = memo(function FunctionRolesFilter({ onChange, onLoad }: any) {
  const { data: functionRoles, isLoading, isFetching } = useQuery(getCollegeFunctionRoleList.QUERY_KEY, () =>
    getCollegeFunctionRoleList(['Primary Campus Resource URL'])
  );

  const sortedFunctionRoles = useMemo(() => _.sortBy(functionRoles, ['description']), [functionRoles]);

  if (!isLoading && !isFetching && functionRoles) {
    onLoad(
      functionRoles.reduce((result: { [key: string]: string }, functionRole: { id: string; description: string }) => {
        result[functionRole.id] = functionRole.description;
        return result;
      }, {})
    );
  }

  return (
    <Select
      placeholder="Please select one or more"
      name="filterByFunctionRole"
      mode="multiple"
      loading={isLoading}
      onChange={onChange}
      filterOption={true}
      optionFilterProp={'title'}
      options={sortedFunctionRoles.map((item: any) => ({ label: item.description, value: item.id }))}
    />
  );
});

export default FunctionRolesFilter;
