import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { List } from 'antd';
import { css } from '@emotion/react';

import MentorCard from './mentor-card';

import MentorsFilter from '../filter/mentors-filter';
import { RootState } from '../../../../../../state/reducers';
import { useQuery } from '../../../../../../hooks/use-query';
import * as api from '../../../../../../apis/users/mentorship';
import UserContent from '../../../../../../components/common/user-content';
import { UserContentLayoutType } from '../../../../../../types/layout';
import theme from '../../../../../../theme';
import { useDebounce } from '../../../../../../hooks/use-debounce';

const UserContentCss = css`
  padding-top: 30px;
  background-color: ${theme.colorsLightestGrey};
`;

const mapState = ({ manualMentorsMatchFilterState, auth }: RootState) => ({
  jobTypeValue: manualMentorsMatchFilterState.jobTypeValue,
  collegeMajorArea: manualMentorsMatchFilterState.collegeMajorArea,
  skills: manualMentorsMatchFilterState.skills,
  locationValue: manualMentorsMatchFilterState.location,
  sexValue: manualMentorsMatchFilterState.sex,
  educationValue: manualMentorsMatchFilterState.education,
  professionalExperience: manualMentorsMatchFilterState.professionalExperience,
  user: auth.user,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

type AvailableMentorsListProps = ConnectedProps<typeof connector>;

const AvailableMentorsList: React.FC<AvailableMentorsListProps> = props => {
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedMentorsIds, setSelectedMentorsIds] = useState([] as string[]);
  const PAGE_SIZE = 10; //default the pageSize as 10

  const onPageChange = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const addMentorIdToSelectedMentors = (mentorId: string) => {
    const newMentorIds: string[] = [...selectedMentorsIds, mentorId];
    setSelectedMentorsIds(newMentorIds);
  };

  const jobTypeValue = useDebounce(props.jobTypeValue, 400);
  const collegeMajorArea = useDebounce(props.collegeMajorArea, 400);
  const skills = useDebounce(props.skills, 400);

  const { data: mentors, isLoading: isMentorsLoading, refetch: loadMentors } = useQuery(
    [
      api.getAvailableMentors.QUERY_KEY,
      {
        pageNumber,
        pageSize: PAGE_SIZE,
        filters: {
          jobFunction: jobTypeValue,
          primaryMajor: collegeMajorArea,
          skills: skills,
          location: props.locationValue,
          sex: props.sexValue,
          education: props.educationValue,
          employmentExperience: props.professionalExperience,
        },
      },
    ],
    () =>
      api.getAvailableMentors({
        pageNumber,
        pageSize: PAGE_SIZE,
        filters: {
          jobFunction: jobTypeValue,
          primaryMajor: collegeMajorArea,
          skills: skills,
          location: props.locationValue,
          sex: props.sexValue,
          education: props.educationValue,
          employmentExperience: props.professionalExperience,
        },
      }),
    {
      manual: true,
    }
  );

  useEffect(() => {
    loadMentors();
  }, [loadMentors, pageNumber]);

  const applyAdvancedFilters = () => {
    loadMentors();
  };

  const onClearAdvancedFilters = () => {
    loadMentors();
  };

  const pagination = {
    position: 'bottom',
    total: mentors?.totalCount ?? 0,
    onChange: onPageChange,
    current: pageNumber,
  };

  const locale = {
    emptyText: 'There are no mentors that match the search criteria',
  };

  const dataSource = mentors?.items
    ? mentors?.items.filter((item: any) => !selectedMentorsIds.includes(item.userId))
    : [];

  return (
    <React.Fragment>
      <MentorsFilter applyAdvancedFilters={applyAdvancedFilters} onClearAdvancedFilters={onClearAdvancedFilters} />
      <UserContent type={UserContentLayoutType.DASHBOARD} css={UserContentCss}>
        <List
          grid={{
            gutter: 30,
            column: 3,
          }}
          dataSource={dataSource}
          loading={isMentorsLoading}
          pagination={pagination}
          locale={locale}
          renderItem={(item: any) => (
            <MentorCard {...item} addMentorIdToSelectedMentors={addMentorIdToSelectedMentors} />
          )}
        />
      </UserContent>
    </React.Fragment>
  );
};

export default connector(AvailableMentorsList);
