import React, { memo } from 'react';
import { Col, Row } from 'antd';

import UserContent from '../../common/user-content';
import { IMessageThread, ThreadStatus } from '../../../types/models/message-center';
import { ClosedAlert, MessageDetailsThread } from '../../common/message-details-common';

type MessageCenterDetailProps = {
  details: IMessageThread;
  onSend?: (text: string) => Promise<boolean>;
};

const MessageCenterMessageDetails = memo(function MessageCenterMessageDetails({
  details,
  onSend,
}: MessageCenterDetailProps) {
  const { status, isArchived } = details;
  return (
    <UserContent>
      <Row gutter={[1, 0]}>
        <Col span={24}>
          {status === ThreadStatus.CLOSED && (
            <ClosedAlert message="This inquiry has been closed." type="info" closable={false} />
          )}
          {isArchived && <ClosedAlert message="This inquiry has been archived." type="info" closable={false} />}
        </Col>
      </Row>
      <MessageDetailsThread details={details} onSend={onSend} />
    </UserContent>
  );
});
export default MessageCenterMessageDetails;
