import React from 'react';
import { Modal, notification } from 'antd';
import { useMutation } from 'react-query';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';

import {
  SelectMentorCancelText,
  SelectMentorConfirmContent,
  SelectMentorConfirmTitle,
  SelectMentorOkText,
} from '../../../common/select-mentor-confirm/select-mentor-confirm';
import * as api from '../../../../../../apis/users/mentorship';
import { RootState } from '../../../../../../state/reducers';
import * as actions from '../../../../../../state/actions/myhsf/mentorship/mentor-bio/mentor-bio';
import UserBioHeader from '../../../../../../components/user/user-bio/user-bio-header';
import { Button } from '../../../../../../components';
import { openChat, setIsLoadingChat } from '../../../../../../state/actions/message';
import { formatUniversity } from '../../../../mentorship/common';
import { getIsLoadingChat, getUserIdForChat } from '../../../../../../state/selectors/message';

const mapState = ({ mentorBioState }: RootState) => ({
  mentor: mentorBioState.mentor,
});

const mapDispatch = {
  setMentor: actions.setMentorData,
};

const connector = connect(mapState, mapDispatch);

type MentorInfoProps = ConnectedProps<typeof connector>;

const MentorInfo: React.FC<MentorInfoProps> = (props) => {
  const dispatch = useDispatch();
  const isLoadingChat = useSelector(getIsLoadingChat);
  const chatUserId = useSelector(getUserIdForChat);

  const { mutateAsync: selectMentorManually } = useMutation<{ isSelected: boolean }, unknown, { mentorId: string }>(
    ({ mentorId }) => api.selectMentorManually(mentorId)
  );

  const selectMentorOk = async () => {
    try {
      await selectMentorManually({ mentorId: props.mentor.id });
      props.setMentor({ ...props.mentor, canSelect: false });

      notification.success({
        message: 'Success',
        description: 'Mentor successfully selected',
        duration: 500,
        placement: 'bottomRight',
      });
    } catch (error) {
      notification.error({
        type: 'error',
        closeIcon: true,
        duration: 500,
        placement: 'bottomRight',
        message: 'Error',
        description: error.message,
      });
    }
  };

  const selectMentor = () => {
    Modal.confirm({
      content: <SelectMentorConfirmContent />,
      title: SelectMentorConfirmTitle,
      okText: SelectMentorOkText,
      cancelText: SelectMentorCancelText,
      maskClosable: true,
      onOk: selectMentorOk,
    });
  };

  const sendMessage = () => {
    dispatch(setIsLoadingChat(true));
    dispatch(openChat(props.mentor.id));
  };

  const location = [props.mentor.city, props.mentor.state]
    .filter((v) => v !== null && v !== undefined && v !== '')
    .join(', ');

  const education = [
    ...(props.mentor.bachelorDegrees?.map((d: any) => formatUniversity(d.degreeSchool)) ?? []),
    ...(props.mentor.masterDegrees?.map((d: any) => formatUniversity(d.degreeSchool)) ?? []),
    ...(props.mentor.doctorateDegrees?.map((d: any) => formatUniversity(d.degreeSchool)) ?? []),
  ].join(', ');

  const collegeMajor = [
    ...(props.mentor.bachelorDegrees?.map((d: any) => d.primaryMajor) ?? []),
    ...(props.mentor.masterDegrees?.map((d: any) => d.primaryMajor) ?? []),
    ...(props.mentor.doctorateDegrees?.map((d: any) => d.primaryMajor) ?? []),
  ].join(', ');

  const mentorInfo = [
    {
      label: 'Location',
      value: location,
    },
    {
      label: 'Job Type',
      value: props.mentor.currentCompanyIndustries?.map((industry: any) => industry.displayText).join(', '),
    },
    {
      label: 'Professional Experience',
      value: props.mentor.employmentExperience,
    },
    {
      label: 'Education',
      value: education,
    },
    {
      label: 'College Major',
      value: collegeMajor,
    },
  ];

  const button = props.mentor.canSelect ? (
    <Button key={'select-mentor'} type={'primary-blue'} onClick={selectMentor} block>
      Select Mentor
    </Button>
  ) : (
    <Button
      key={'send-message'}
      type={'primary-blue'}
      onClick={sendMessage}
      block
      loading={isLoadingChat && chatUserId === props.mentor?.id}
    >
      Send Message
    </Button>
  );

  return (
    <UserBioHeader
      title={`${props.mentor.firstName} ${props.mentor.lastName}`}
      image={props.mentor?.imageUrl}
      subtitle={props.mentor.jobTitle}
      userInfoRows={mentorInfo}
      button={button}
    />
  );
};

export default connector(MentorInfo);
