import axios from 'axios';

import { API_URL } from '../../constants';
import { ICollegeDetails, ICollegeResourceData, CategoryInFilter } from '../../types/models/college-navigator';
import { createError } from '../error';
import responseParser from '../response-parser';

export const getCollege = async (collegeId: string): Promise<ICollegeDetails> => {
  const url = `${API_URL}/college/${collegeId}`;

  try {
    const response = await axios.get(url);
    return responseParser(response);
  } catch (e) {
    throw createError(e);
  }
};
getCollege.QUERY_KEY = 'scholar:getCollege';

const parseCollegeResourceData = (collegeResource: ICollegeResourceData): ICollegeResourceData => ({
  ...collegeResource,
  phone: collegeResource.phone?.replace(/-/gi, ''),
  categoryName: collegeResource.category.description,
});

export const getCollegeResources = async (collegeId: string): Promise<ICollegeResourceData[]> => {
  const url = `${API_URL}/college-resources/${collegeId}`;
  try {
    const response = await axios.get(url);
    return responseParser(response).map(parseCollegeResourceData);
  } catch (e) {
    throw createError(e);
  }
};
getCollegeResources.QUERY_KEY = 'scholar:getCollegeResources';

export const getCollegeCategoryList = async (): Promise<CategoryInFilter[]> => {
  const url = `${API_URL}/college-resources-category`;
  try {
    const response = await axios.get(url);
    return responseParser(response);
  } catch (e) {
    throw createError(e);
  }
};
getCollegeCategoryList.QUERY_KEY = 'scholar:getCollegeCategoryList';

export const getCollegeFunctionRoleList = async (filter?: string[]): Promise<any> => {
  let url = `${API_URL}/college-resources-function-role`;
  if (filter) {
    url += `?filter=`;
    filter.forEach((f, idx, arr) => {
      url += `${f}`;
      if (idx < arr.length - 1) {
        url += '&filter=';
      }
    });
  }

  try {
    const response = await axios.get(url);
    return responseParser(response);
  } catch (e) {
    throw createError(e);
  }
};
getCollegeFunctionRoleList.QUERY_KEY = 'scholar:getCollegeFunctionRoleList';

export const createCollegeResourceSuggestion = async (suggestion: any): Promise<any> => {
  const url = `${API_URL}/college-resources-suggestion`;

  const data = {
    collegeResourcesId: suggestion.id,
    data: {
      functionRoleId: suggestion.functionRoleId,
      contactName: suggestion.contactName,
      contactTitle: suggestion.contactTitle,
      officeDepartmentName: suggestion.officeDepartmentName,
      email: suggestion.email,
      phone: suggestion.phone,
      website: suggestion.website,
      address: suggestion.adddress,
    },
  };

  try {
    const response = await axios.post(url, data);
    return responseParser(response);
  } catch (e) {
    throw createError(e);
  }
};
createCollegeResourceSuggestion.QUERY_KEY = 'scholar:createCollegeResourceSuggestion';
