import React, { memo, useCallback } from 'react';
import styled from '@emotion/styled';
import { Row, Col } from 'antd';

import theme from '../../../theme';
import { DOCUMENT_TYPE_LABELS } from '../../../constants';
import { formatDate } from '../../../utils/date';
import { sortDocuments } from '../../../utils/sort';
import { IDocument } from '../../../types/models/document';

type DocumentBankListProp = {
  documentList: IDocument[];
  onDownload: (id: string, name: string) => void;
};

const DocumentListTable = styled.div`
  font-size: 16px;
  margin-top: 32px;
  @media (max-width: ${theme.screenLgMax}) {
    margin-top: 12px;
  }
`;
const DocumentRow = styled(Row)`
  padding-bottom: 24px;
`;
const DocumentNameCol = styled(Col)`
  width: 80%;
  @media (max-width: ${theme.screenLgMax}) {
    width: 100%;
  }
`;

const DocumentNameHeader = styled.div`
  overflow: hidden;
  font-weight: bold;
  color: ${theme.colorsBlack};
  margin-bottom: 4px;
`;

const DocumentNameValue = styled.div`
  text-decoration: underline;
  color: ${theme.colorsSecondaryBlue};
  display: block;
  word-break: break-all;
  overflow: hidden;
  &:hover {
    font-weight: bold;
    border: none;
    box-shadow: none;
    cursor: pointer;
  }
`;
const DocumentDateCol = styled.div`
  width: 20%;
  color: ${theme.colorsDisabledGreyText};
  font-size: 14px;
  @media (max-width: ${theme.screenLgMax}) {
    width: 100%;
    margin: 0px 12px;
  }
`;
const NO_RECORD_FOUND = 'No uploaded documents';

const renderDate = (dateString?: string) => {
  return dateString ? `Uploaded ${formatDate(dateString, 'MM/DD/YYYY')}` : '';
};

const DocumentBankList = memo(function DocumentBankContainer({ documentList, onDownload }: DocumentBankListProp) {
  const renderDocumentListData = useCallback(() => {
    return documentList.length > 0 ? (
      documentList.sort(sortDocuments).map((item: IDocument) => {
        const headerLabel = DOCUMENT_TYPE_LABELS.get(item.documentType);
        return (
          <DocumentRow justify="space-between" align="middle" key={item.id} gutter={[24, 24]}>
            <DocumentNameCol>
              <DocumentNameHeader title={headerLabel}>{headerLabel}</DocumentNameHeader>
              <DocumentNameValue onClick={() => onDownload(item.id, item.fileName)} title={item.fileName}>
                {item.fileName}
              </DocumentNameValue>
            </DocumentNameCol>
            <DocumentDateCol>{renderDate(item.date)}</DocumentDateCol>
          </DocumentRow>
        );
      })
    ) : (
      <div>{NO_RECORD_FOUND}</div>
    );
  }, [documentList, onDownload]);

  return (
    <div>
      <DocumentListTable>{renderDocumentListData()}</DocumentListTable>
    </div>
  );
});

export default DocumentBankList;
