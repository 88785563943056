import React, { ReactNode, Fragment } from 'react';
import { Redirect, RouteComponentProps } from '@reach/router';
import { useSelector } from 'react-redux';

import { isMentorMatchEnabled, isConferenceMentorMatchEnabled } from '../../../state/selectors/tenant';

type MentorshipProps = RouteComponentProps & {
  children?: ReactNode;
};
const Mentorship = (props: MentorshipProps) => {
  const isMentorMatchEnable = useSelector(isMentorMatchEnabled());
  const isConferenceMentorMatchEnable = useSelector(isConferenceMentorMatchEnabled());

  return isMentorMatchEnable || isConferenceMentorMatchEnable ? <Fragment>{props.children}</Fragment> : <Redirect noThrow to={'/myhsf/dashboard'} />;
};

export default Mentorship;
