import React from 'react';
import { RouteComponentProps } from '@reach/router';

import CollegeNavigatorComponent from '../../components/user/college-navigator/college-navigator';
import SEO from '../../components/seo';

const CollegeNavigator = (_: RouteComponentProps) => {
  return (
    <>
      <SEO title="College Navigator" />
      <CollegeNavigatorComponent />
    </>
  );
};

export default CollegeNavigator;
