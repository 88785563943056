import React from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';

import MoreFiltersButton from './more-filters-button';
import FilterSelect from './filter-select';
import AdvancedFilters from './advanced-filters';

import { Skills } from '../../../common';
import theme from '../../../../../../theme';
import { FormElementChangeEvent } from '../../../../../../components/forms';
import { SelectProps } from '../../../../../../components/forms/select';
import { RootState } from '../../../../../../state/reducers';
import * as actions from '../../../../../../state/actions/myhsf/mentorship/manual-application/mentors-list/mentors-filter';
import { LookupType } from '../../../../../../types/models/lookup';
import { LookupOption } from '../../../../../../types/models/user-management';
import { useLookupByType } from '../../../../../../state/actions/lookup';
import { getAllLookupsFromState } from '../../../../../../state/selectors/lookup';
import { sortMemoryLookups } from '../../../../../../../src/utils/sort';

const MainFiltersSection = styled.div`
  width: 100%;
  padding: 15px 0 30px 0;
  background: ${theme.colorsWhite};
`;

const MainFiltersContainer = styled.div`
  max-width: ${theme.screenXl};
  margin: 0 auto;
  @media (max-width: ${theme.screenLgMax}) {
    padding: 0 24px;
    margin: 0;
    max-width: none;
  }
`;

export const remapOptions = ({ displayText, id }: LookupOption) => ({ label: displayText, value: id });

const DEFAULT_OPTION = {
  [LookupType.JOB_FUNCTIONS]: { label: 'Job Type', value: '' },
  [LookupType.MAJOR_CLASSIFICATION]: { label: 'College Major/Focus Area', value: '' },
};

const lookupsNames = [LookupType.JOB_FUNCTIONS, LookupType.MAJOR_CLASSIFICATION];

const mapState = ({ manualMentorsMatchFilterState }: RootState) => ({
  jobTypeValue: manualMentorsMatchFilterState.jobTypeValue,
  collegeMajorArea: manualMentorsMatchFilterState.collegeMajorArea,
  skills: manualMentorsMatchFilterState.skills,
  showAdvancedFilters: manualMentorsMatchFilterState.visibleAdvancedFilters,
});

const mapDispatch = {
  onJobTypeChange: actions.handleJobTypeChange,
  onCollegeMajorAreaChange: actions.handleCollegeMajorAreaChange,
  onSkillsChange: actions.handleSkillsChange,
  toggleAdvancedFilters: actions.toggleVisibleAdvancedFiltersAction,
};

const connector = connect(mapState, mapDispatch);

type MentorsFilterProps = ConnectedProps<typeof connector> & {
  applyAdvancedFilters(): void;
  onClearAdvancedFilters(): void;
};

const MentorsFilter: React.FC<MentorsFilterProps> = props => {
  const memoryLookups = useSelector(getAllLookupsFromState);
  const { isLoading: isLookupsLoading } = useLookupByType(lookupsNames);
  const onJobTypeChange = (e: FormElementChangeEvent) => {
    props.onJobTypeChange(e.value);
  };

  const onCollegeAreaChange = (e: FormElementChangeEvent) => {
    props.onCollegeMajorAreaChange(e.value);
  };

  const onSkillsChange = (e: FormElementChangeEvent) => {
    props.onSkillsChange(e.value);
  };

  const openAdvancedFilters = (_: React.MouseEvent<HTMLButtonElement>) => {
    props.toggleAdvancedFilters();
  };

  const onCloseAdvancedFilters = () => {
    props.toggleAdvancedFilters();
  };

  const onApplyAdvancedFilters = () => {
    props.applyAdvancedFilters();
    props.toggleAdvancedFilters();
  };

  const onClearAdvancedFilters = () => {
    props.onClearAdvancedFilters();
  };

  const getOptions = (lookupName: LookupType.JOB_FUNCTIONS | LookupType.MAJOR_CLASSIFICATION) => {
    if (memoryLookups[lookupName]) {
      if (lookupName === LookupType.MAJOR_CLASSIFICATION) {
        const majorClassificationOptions = [...memoryLookups[LookupType.MAJOR_CLASSIFICATION]];
        return [DEFAULT_OPTION[lookupName], ...majorClassificationOptions.sort(sortMemoryLookups).map(remapOptions)];
      }
      return [DEFAULT_OPTION[lookupName], ...memoryLookups[lookupName].map(remapOptions)];
    }
    return [DEFAULT_OPTION[lookupName]];
  };

  const filterSelectParams: Array<SelectProps> = [
    {
      mode: 'default',
      value: props.jobTypeValue,
      onChange: onJobTypeChange,
      options: getOptions(LookupType.JOB_FUNCTIONS),
      size: 'large',
      showArrow: true,
      loading: isLookupsLoading,
      showSearch: true,
      filterOption: (input, option) =>
        option?.props?.children !== null &&
        typeof option.props.children === 'string' &&
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    },
    {
      mode: 'default',
      value: props.collegeMajorArea,
      onChange: onCollegeAreaChange,
      options: getOptions(LookupType.MAJOR_CLASSIFICATION),
      size: 'large',
      showArrow: true,
      loading: isLookupsLoading,
      showSearch: true,
      filterOption: (input, option) =>
        option?.props?.children !== null &&
        typeof option.props.children === 'string' &&
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    },
    {
      mode: 'multiple',
      value: props.skills,
      onChange: onSkillsChange,
      placeholder: 'Skills',
      options: Skills,
      size: 'large',
      showArrow: true,
    },
  ];

  const filterSelects: React.ReactNode = filterSelectParams.map((params, i) => (
    <Col key={`select-filter-${i}`} span={6}>
      <FilterSelect {...params} />
    </Col>
  ));

  return (
    <MainFiltersSection>
      <MainFiltersContainer>
        <Row>
          {filterSelects}
          <Col span={6}>
            <MoreFiltersButton openMoreFilters={openAdvancedFilters} />
          </Col>
        </Row>
        {props.showAdvancedFilters && (
          <AdvancedFilters
            visible={props.showAdvancedFilters}
            handleApply={onApplyAdvancedFilters}
            handleCancel={onCloseAdvancedFilters}
            handleClear={onClearAdvancedFilters}
          />
        )}
      </MainFiltersContainer>
    </MainFiltersSection>
  );
};

export default connector(MentorsFilter);
