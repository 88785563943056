import React, { memo, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { useQuery } from 'react-query';

import DocumentBankList from './document-bank-list';

import UserContent from '../../common/user-content';
import theme from '../../../theme';
import { Button, Alert } from '../..';
import PageHeader, { PageHeaderProps } from '../../common/page-header';
import { DocumentType } from '../../../types/models/document';
import { DOCUMENT_TYPE_LABELS } from '../../../constants';
import { Select, FormElementChangeEvent, FileUpload, OptionProps } from '../../forms';
import { getDocuments, getDocument } from '../../../apis/users/documents';
import Loader from '../../../components/common/loader';

type DocumentBankContainerProp = {
  title: PageHeaderProps['title'];
  breadcrumb?: PageHeaderProps['breadcrumb'];
  description?: PageHeaderProps['description'];
};
const DocumentListTitle = styled.div`
  color: ${theme.colorsBlack};
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
`;

const SectionTitle = styled.div`
  font-size: 22px;
  color: ${theme.colorsBlack};
  font-weight: bold;
  padding-top: 50px;
  padding-bottom: 32px;
  @media (max-width: ${theme.screenLgMax}) {
    width: 100%;
    padding: 24px 0px;
  }
`;
const DocumentSelect = styled(Select)`
  max-width: 354px;
`;
const SectionSeparateLine = styled.hr`
  margin: 48px 0px;
  @media (max-width: ${theme.screenLgMax}) {
    margin: 24px 0px;
  }
`;
const ComponentTitle = styled.div`
  color: ${theme.colorsBlack};
  font-weight: bold;
  padding-bottom: 10px;
  font-size: 16px;
  @media (max-width: ${theme.screenLgMax}) {
    width: 100%;
  }
`;
const UploadTitle = styled(ComponentTitle)`
  margin-top: 16px;
`;
const DocumentBankUpload = styled.div`
  max-width: 449px;
`;
const RefreshButton = styled(Button)`
  &.ant-btn {
    margin-top: 20px;

    @media (max-width: ${theme.screenLgMax}) {
      margin: 20px auto;
      text-align: center;
      display: block;
      width: 100%;
    }
  }
`;

export const DOCUMENT_LIST_TITLE = <DocumentListTitle>All Documents</DocumentListTitle>;

const DEFAULT_SELECT_LABEL = 'Select One';
export const SELECT_DOCUMENT_TYPE_DATA_TEST_ID = 'select-document-type';

const options: OptionProps[] = [{ label: DEFAULT_SELECT_LABEL, value: '' }].concat(
  Array.from(DOCUMENT_TYPE_LABELS).map(([value, label]) => ({
    label,
    value: value.toString(),
  }))
);

const DocumentBank = memo(function DocumentBank({ title, breadcrumb, description }: DocumentBankContainerProp) {
  const [selectedItem, setSelectedItem] = useState<DocumentType>();
  const [uploadedFileName, setUploadedFileName] = useState<string>();
  const [uploadDoneUpdateCount, setUploadDoneUpdateCount] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>();
  const {
    data: documentList,
    isLoading: isLoadingDocumentList,
    error: errorDocumentList,
  } = useQuery([getDocuments.QUERY_KEY, { uploadDoneUpdateCount }], () =>
    getDocuments().then((data) => data.filter((item) => item.documentType !== DocumentType.GENERIC) || [])
  );

  const onSelectChange = useCallback(({ value }: FormElementChangeEvent) => {
    setSelectedItem(value);
  }, []);
  const onUploadChange = useCallback(({ value }: FormElementChangeEvent) => {
    setUploadedFileName(value);
  }, []);
  const onRefreshBank = useCallback(() => {
    setUploadedFileName('');
    setSelectedItem(undefined);
    setUploadDoneUpdateCount(uploadDoneUpdateCount + 1);
  }, [uploadDoneUpdateCount]);

  /**
   * Handle download the document in the document bank
   */
  const onDownloadClickHandler = useCallback(async (id: string, fileName: string) => {
    try {
      const file: File = await getDocument(id, true);
      const objectUrl = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = objectUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      setErrorMessage(`Document cannot be downloaded.`);
    }
  }, []);

  /**
   * Render all latest documents with different document types in the document bank
   */
  const renderAllDocuments = () => {
    if (isLoadingDocumentList) {
      return <Loader />;
    }

    if (errorDocumentList) {
      return <p>{errorDocumentList.message}</p>;
    }

    return (
      <div>
        {errorMessage && <Alert message={errorMessage} type="error" closable={false} />}
        {documentList && <DocumentBankList documentList={documentList} onDownload={onDownloadClickHandler} />}
      </div>
    );
  };

  return (
    <>
      <PageHeader title={title} breadcrumb={breadcrumb} description={description} />
      <UserContent>
        <SectionTitle>Upload a Document</SectionTitle>
        <ComponentTitle>Select Document Type</ComponentTitle>
        <DocumentSelect
          showSearch
          value={selectedItem}
          optionFilterProp="children"
          onChange={onSelectChange}
          options={options}
          placeholder={DEFAULT_SELECT_LABEL}
          data-testid={SELECT_DOCUMENT_TYPE_DATA_TEST_ID}
          filterOption={(input, option) =>
            option &&
            option.props !== null &&
            option.props.children !== null &&
            typeof option.props.children === 'string' &&
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        />
        {/* only when a select an item,  */}
        {selectedItem && (
          <>
            <UploadTitle>Upload Document</UploadTitle>
            <DocumentBankUpload>
              <FileUpload documentType={selectedItem} onChange={onUploadChange} />
            </DocumentBankUpload>
          </>
        )}
        {/* only when a file has been selected  */}
        {uploadedFileName && (
          <RefreshButton type="primary-blue" onClick={onRefreshBank}>
            Done
          </RefreshButton>
        )}
        <SectionSeparateLine />
        {DOCUMENT_LIST_TITLE}
        {renderAllDocuments()}
      </UserContent>
    </>
  );
});

export default DocumentBank;
