import React from 'react';
import styled from '@emotion/styled';
import { List, Progress, Row } from 'antd5';

import TrackImage from './track-image';

import { IFilteredTrackDetails } from '../../../types/models/hsf-university';
import { startTrack } from '../../../apis/users/hsf-university';
import theme from '../../../theme';
import { Button, Modal } from '../../../components';
import { getTrackProgress } from '../../../utils/hsf-university';

const Container = styled.div`
  background-color: ${theme.colorsLightestGrey};
`;

const StyledContainerContent = styled(List)`
  padding: 10px 0 20px 0 !important;

  .ant-spin-container {
    min-height: 500px !important;
  }

  & > div {
    max-width: ${theme.screenXl};
    margin: 0 auto;

    @media (max-width: ${theme.screenXl}) {
      padding: 0 24px;
    }
  }
`;

export const BlockRow = styled(Row)`
  position: relative;
  height: auto;
  margin-right: 0;
  margin-left: 0;
  zoom: 1;
  display: block !important;
  box-sizing: border-box;
`;

const StyledItem = styled(BlockRow)`
  padding: 25px;
  margin: 31.5px 0 31.5px;
  border: 1px solid ${theme.colorsHr};
  border-radius: 4px;
  background-color: ${theme.colorsWhite};
  min-height: 523px;
  align-items: flex-end;
`;

const Wrapper = styled.div`
  margin-bottom: 0.5rem;
`;

const StyledDivTitle = styled.div`
  margin-bottom: 0.625rem;
  font-size: 18px;
  font-weight: bold;
  word-break: break-word;
  color: ${theme.colorsBlack};
  line-height: 24px;
  max-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledDivAmount = styled.div`
  font-size: 16px;
  color: ${theme.colorsBlack};
  line-height: 20px;
`;

const StyledDivDescription = styled.div`
  max-width: 305px;
  margin-bottom: 1rem;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.colorsBlack};
  text-overflow: ellipsis;
  min-height: 118px;
`;

const Percent = styled.p`
  margin: 0 0 4px 0;
  padding: 0;
  color: ${theme.colorsBlack};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.25px;
  line-height: 19px;
  text-align: right;
`;

const StyledProgress = styled(Progress)`
  margin-bottom: 8px;
`;

const StyledDivButtons = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 47%;
  }
`;

type TrackListProps = {
  dataSource: IFilteredTrackDetails[];
  onHandleDetail: (id: any) => void;
  tracksType: string;
  errorView?: React.ReactNode;
  loading?: boolean;
};

function TracksList({ dataSource, onHandleDetail, tracksType, errorView, loading }: TrackListProps) {
  const handleStartTrack = async (trackId: string) => {
    try {
      await startTrack(trackId);
      onHandleDetail(trackId);
    } catch (error: any) {
      Modal.error({ title: 'Start Track', content: error.message });
    }
  };

  return (
    <Container>
      <StyledContainerContent
        itemLayout="horizontal"
        pagination={{
          pageSize: 6,
          hideOnSinglePage: true,
          position: 'both',
          simple: true,
        }}
        grid={{ gutter: 6, column: 3 }}
        size="large"
        loading={loading}
        dataSource={dataSource}
        locale={{ emptyText: errorView }}
        renderItem={(item: any) => (
          <List.Item>
            <StyledItem>
              {item.imageId && <TrackImage name={item.name} imgId={item.imageId} />}
              <List.Item.Meta
                title={
                  <Wrapper>
                    <StyledDivTitle>{item.name}</StyledDivTitle>
                    {item.courses && (
                      <StyledDivAmount>
                        {item.courses.length}
                        {item.courses.length === 1 ? ' course' : ' courses'}
                      </StyledDivAmount>
                    )}
                  </Wrapper>
                }
                description={
                  <div>
                    <StyledDivDescription>
                      {!item.description || item.description.length < 180 ? (
                        item.description
                      ) : (
                        <>{`${item.description?.slice(0, 180)}...     `}</>
                      )}
                    </StyledDivDescription>
                    <Percent>{item.courses ? getTrackProgress(item.courses) : 0}% complete</Percent>
                    <StyledProgress
                      percent={item.courses ? getTrackProgress(item.courses) : 0}
                      size={['100%', 11]}
                      strokeColor={theme.colorsPrimaryBlue}
                      showInfo={false}
                    />
                    <StyledDivButtons>
                      <Button type="primary-blue-outline" onClick={() => onHandleDetail(item.id)}>
                        View More
                      </Button>
                      {tracksType === 'recommended' && (
                        <Button onClick={() => handleStartTrack(item.id)} type="primary-blue">
                          Start Track
                        </Button>
                      )}
                      {tracksType === 'inProgress' && (
                        <Button onClick={() => onHandleDetail(item.id)} type="primary-blue">
                          Continue
                        </Button>
                      )}
                    </StyledDivButtons>
                  </div>
                }
              />
            </StyledItem>
          </List.Item>
        )}
      />
    </Container>
  );
}

export default TracksList;
