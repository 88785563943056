import React from 'react';
import { Result } from 'antd';
import { PeopleAlt } from '@material-ui/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const resultCss = css({
  padding: '20vh 32px',
  '.ant-result-icon': {
    marginBottom: 0,
  },
  '.ant-result-title': {
    lineHeight: 1.2,
    marginBottom: '2%',
  },
  '.ant-result-subtitle': {
    color: 'black',
  },
  '.ant-result-extra': {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  '.ant-result-content': {
    padding: 0,
    marginTop: 0,
  },
});

const StyledPeopleAlt = styled(PeopleAlt)`
  font-size: 75px !important;
`;

export const NoMentees: React.FC<any> = props => {
  if (!props.active) {
    return <Result css={resultCss} icon={<StyledPeopleAlt />} title="You have no Past Mentees yet" />;
  }

  return <Result css={resultCss} icon={<StyledPeopleAlt />} title="You have no Active Mentees yet" />;
};
