import React, { memo } from 'react';
import { Modal, Row, Col, List } from 'antd';
import styled from '@emotion/styled';

import { DEFAULT_MODAL_WIDTH } from '../../../constants';

const Body = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
  color: black;
  max-height: 65vh;
  overflow-y: scroll;
`;

const TitleRow = styled(Row)`
  margin-bottom: 10px;
`;

const ItemStyle = styled.div`
  font-size: 16px;
  color: black;
`;

type ModalProps = {
  data: any;
  handleCancel: Function;
};

const MajorCategoriesModal = memo(function MajorCategoriesModal({ data, handleCancel }: ModalProps) {
  return (
    <>
      <Modal
        width={DEFAULT_MODAL_WIDTH}
        title={data.description}
        visible={true}
        centered
        closable={true}
        onCancel={() => {
          handleCancel && handleCancel();
        }}
        footer={[]}
      >
        <Body>
          <TitleRow align="middle" justify="start">
            <Col xs={24}>
              <b>Majors within this category are:</b>
            </Col>
          </TitleRow>

          <Row align="middle" justify="start">
            <Col xs={24}>
              <List
                size="large"
                itemLayout="horizontal"
                dataSource={data.majors}
                renderItem={(item: any) => (
                  <List.Item>
                    <ItemStyle>&bull;&nbsp;{item.description}</ItemStyle>
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </Body>
      </Modal>
    </>
  );
});

export default MajorCategoriesModal;
