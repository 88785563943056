import AccountSettingsContainer from '../../components/user/account-settings/account-settings';
import { Link } from 'gatsby';
import React from 'react';
import { RouteComponentProps } from '@reach/router';
import SEO from '../../components/seo';
import { tenantLabel } from '../../siteContent';

const AccountSettings = (_: RouteComponentProps) => {
  return (
    <>
      <SEO title="Account Settings" />
      <AccountSettingsContainer
        title="Account Settings"
        breadcrumb={[
          <Link key="1" to="/myhsf">
            {tenantLabel} Dashboard
          </Link>,
          'Account Settings',
        ]}
      />
    </>
  );
};

export default AccountSettings;
