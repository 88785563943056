import React, { memo, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';

import { CategoryInFilter } from '../../../types/models/college-navigator';
import { getCollegeCategoryList } from '../../../apis/users/college-navigator';
import { Select } from '../../../components/forms';

const CategoriesFilter = memo(function CategoriesFilter({ onChange, onLoad }: any) {
  const [categories, setCategories] = useState<CategoryInFilter[]>([]);
  const sortedCategories = useMemo(() => _.sortBy(categories, ['description']), [categories]);

  const { isLoading, isFetching } = useQuery(getCollegeCategoryList.QUERY_KEY, () => getCollegeCategoryList(), {
    onSuccess: categories => {
      setCategories(
        categories.filter(
          category => category.description.toLowerCase() !== 'Primary Campus Resource URL'.toLowerCase()
        )
      );
    },
  });

  if (!isLoading && !isFetching) {
    onLoad(
      categories?.reduce((result: { [key: string]: string }, category: { id: string; description: string }) => {
        result[category.id] = category.description;
        return result;
      }, {})
    );
  }

  return (
    <Select
      placeholder="Please select one or more"
      name="filterByCategory"
      mode="multiple"
      loading={isLoading}
      onChange={onChange}
      filterOption={true}
      optionFilterProp={'title'}
      options={sortedCategories.map((item: any) => ({ label: item.description, value: item.id }))}
    />
  );
});

export default CategoriesFilter;
