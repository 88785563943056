import { find, isEmpty, isArray, set, includes } from 'lodash';

function getCollectionWithStatus(item: any, checkedCollection: any, completedMilestones: [], isMilestone: boolean) {
  find(checkedCollection, ['status', 'inProgress'])
    ? set(item, 'status', 'inProgress')
    : set(
        item,
        'status',
        !isMilestone ? 'completed' : includes(completedMilestones, item.id) ? 'completed' : 'tracksCompleted'
      );

  return item;
}

export function getUserMilestones(finishedCourses: [], milestonesList: [], completedMilestones: []) {
  if (!isEmpty(milestonesList)) {
    milestonesList.forEach((milestone: any) => {
      milestone?.tracks.forEach((track: any) => {
        if (isArray(track.courses)) {
          track.courses.forEach((course: any) => {
            return find(finishedCourses, ['uuid', course.id])
              ? set(course, 'status', 'completed')
              : set(course, 'status', 'inProgress');
          });
          return getCollectionWithStatus(track, track.courses, completedMilestones, false);
        }
      });
      return getCollectionWithStatus(milestone, milestone.tracks, completedMilestones, true);
    });
    return milestonesList;
  } else {
    return [];
  }
}
