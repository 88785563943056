import styled from '@emotion/styled';
import { List } from 'antd';

import theme from '../../../theme';

export const StyledContainerTracks = styled.div`
  width: 100%;
  margin-bottom: 20px;
  background-color: #fff;

  & > div:first-of-type {
    box-shadow: inset 0 -1px 0 0 ${theme.colorsHr};
  }
`;

export const StyledHeaderContainer = styled.div`
@media (max-width: ${theme.screenXl}) {
  padding: 0 24px;
}

  &>div:first-of-type,
  &>div:last-of-type {
    max-width: ${theme.screenXl};
    margin: 0 auto;
    }
  }
`;

export const StyledContainerContent = styled(List)`
  background-color: ${theme.colorsLightestGrey};
  padding-top: 20px;
  padding-bottom: 40px;

  & > div {
    max-width: ${theme.screenXl};
    margin: 0 auto;

    @media (max-width: ${theme.screenXl}) {
      padding: 0 24px;
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 188px;

  img {
    max-height: 188px;
    height: auto;
    width: auto;
    margin-top: 8px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 188px;

  img {
    height: 120px;
    width: auto;
    margin-top: 8px;
  }
`;

export const Wrapper = styled.div`
  margin: 24px auto 64px;
  text-align: center;
  color: ${theme.colorsBlack};
  font-size: 16px;
  line-height: 24px;
`;
