import { Row } from 'antd';
import { css } from '@emotion/react';
import React from 'react';
import styled from '@emotion/styled';

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  display: contents;
`;

interface MentorInfoRowProps {
  label: string;
  value: string | null | undefined;
  customCss?: any;
}

const MentorInfoRow = ({ label, value, customCss }: MentorInfoRowProps) => {
  return (
    <Row type={'flex'} css={customCss ?? css({ color: 'black' })}>
      {value ? (
        <React.Fragment>
          <Label>{`${label}:`}&nbsp;</Label>
          {value}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Label>{`${label}:`}&nbsp;</Label>-
        </React.Fragment>
      )}
    </Row>
  );
};

export default MentorInfoRow;
