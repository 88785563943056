import { EmailNotificationsType } from './account-settings';

export interface ISettings {
  messageCenter: IMessageCenterSetting;
  push: IPushSettings;
}

export interface IMessageCenterSetting {
  notificationFrequency: EmailNotificationsType;
}

export interface IPushSettings {
  messageCenterPush: UserSettingsPushStatus;
  realTimeChatPush: UserSettingsPushStatus;
}

export enum UserSettingsPushStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export type UpdateEmail = {
  newEmail?: string;
  currentPassword?: string;
};

export type ResetPassword = {
  currentPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
};
