import React, { memo, useCallback } from 'react';
import styled from '@emotion/styled';
import { Row, Col } from 'antd';
import DOMPurify from 'isomorphic-dompurify';

import PageHeader, { PageHeaderProps, PageHeaderButton } from '../../common/page-header';
import { UserContentLayoutType } from '../../../types/layout';
import UserContent from '../../common/user-content';
import { ResponsiveTable as Table, ITableQueryParams, RequestData } from '../../../components/table';
import { UserTableDataSourceType } from '../../../types/models/message-center';
import { formatDate } from '../../../utils/date';
import { useResponsive } from '../../../hooks/use-responsive';
import theme from '../../../theme';
import { IGetMessagesRequestParams, SortDirection } from '../../../types/dto/message-center';
import { sortMessageDate } from '../../../utils/sort';

type MessageCenterDashboardProps<T, U> = {
  title: PageHeaderProps['title'];
  breadcrumb?: PageHeaderProps['breadcrumb'];
  onRequest: (requestParams: { params: U }) => Promise<RequestData<T>>;
  onCreate: () => void;
  onMessageDetails: (threadId: string) => void;
};

const StyledUserContent = styled(UserContent)`
  .table-row-clickable {
    cursor: pointer;
  }
  @media (max-width: ${theme.screenLgMax}) {
    && {
      .ant-table-tbody {
        > tr {
          > td {
            border-bottom: 1px solid #ddd;
            padding: 12px 0 17px 0;
          }
        }
      }
    }
  }
`;

const MobileRow = styled(Row)`
  margin: 2px 0;
`;

export const MobileColBase = styled(Col, {
  shouldForwardProp: prop => prop !== 'ellipsis',
})<{ ellipsis?: boolean }>``;

const MobileCol = styled(MobileColBase)`
  flex: ${props => (props.ellipsis ? 1 : '0 1 auto')};
  white-space: ${props => (props.ellipsis ? 'nowrap' : 'normal')};
  overflow: ${props => (props.ellipsis ? 'hidden' : 'visible')};
  text-overflow: ${props => (props.ellipsis ? 'ellipsis' : 'clip')};
  min-width: ${props => (props.ellipsis ? '0' : 'auto')};
`;

const MobileGreyText = styled(MobileCol)`
  color: ${theme.colorsDisabledGreyText};
  font-size: 14px;
`;

const MobileDate = styled(MobileGreyText)`
  margin-left: 10px;
`;

export const CREATE_BUTTON_DATA_TEST_ID = 'create-button';

function transformQuery({ limit, offset, searchText, sort }: ITableQueryParams) {
  const hasSort = !!(sort && sort.field);
  const queryParam: IGetMessagesRequestParams = {
    limit,
    offset,
    search: searchText,
    sortBy: hasSort ? sort.field : undefined,
    sortDirection: hasSort ? (sort.order === 'ascend' ? SortDirection.ASC : SortDirection.DESC) : undefined,
  };
  return queryParam;
}

const MessageCenterDashboard = memo(function MessageCenterDashboard({
  title,
  breadcrumb,
  onCreate,
  onRequest,
  onMessageDetails,
}: MessageCenterDashboardProps<UserTableDataSourceType, IGetMessagesRequestParams>) {
  const { xl } = useResponsive();
  const isMobile = !xl;

  const getColumnTextStyle = (text: string, isBold: boolean) => {
    const plainText = text.replace(/<[^>]+>/g, ' ');

    return <span style={isBold ? { fontWeight: 'bold' } : {}}>{plainText}</span>;
  };

  const renderBoldText = (text: string, record: UserTableDataSourceType) => {
    return getColumnTextStyle(text, record.unread);
  };

  const renderDate = (text: string, record: UserTableDataSourceType) => {
    return renderBoldText(formatDate(text, 'MM/DD/YY h:mm A Z'), record);
  };

  const renderMobileColumn = (_: string, record: UserTableDataSourceType) => {
    const title = getColumnTextStyle(record.from, true);
    const subject = getColumnTextStyle(record.subject, record.unread);
    const date = formatDate(record.date, 'MM/DD/YY');
    const { body } = record;
    return (
      <>
        <MobileRow align="middle" justify="space-between">
          <MobileCol ellipsis>{title}</MobileCol>
          <MobileDate>{date}</MobileDate>
        </MobileRow>
        <MobileRow align="middle">
          <MobileCol ellipsis>{subject}</MobileCol>
        </MobileRow>
        <MobileRow align="middle">
          <MobileGreyText ellipsis dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }} />
        </MobileRow>
      </>
    );
  };

  const column = [
    {
      title: 'From',
      dataIndex: 'from',
      key: 'from',
      render: renderBoldText,
      ellipsis: true,
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      render: renderBoldText,
      ellipsis: true,
    },
    {
      title: 'Body',
      dataIndex: 'body',
      key: 'body',
      render: renderBoldText,
      ellipsis: true,
    },
    {
      title: 'Time',
      dataIndex: 'date',
      key: 'date',
      render: renderDate,
      sorter: sortMessageDate,
      defaultSortOrder: 'descend' as const,
      sortDirections: ['ascend' as const, 'descend' as const],
    },
  ];

  const columnMobile = [
    {
      title: 'Time',
      dataIndex: 'date',
      key: 'date',
      render: renderMobileColumn,
      sorter: sortMessageDate,
      defaultSortOrder: 'descend' as const,
      sortDirections: ['ascend' as const, 'descend' as const],
    },
  ];

  const handleRowClick = useCallback(
    (record: UserTableDataSourceType) => {
      onMessageDetails(record.id);
    },
    [onMessageDetails]
  );

  const rowClassName = useCallback(() => 'table-row-clickable', []);
  return (
    <>
      <PageHeader
        title={title}
        breadcrumb={breadcrumb}
        actions={[
          <Row key="1" gutter={[16, 0]} align="middle" justify="end">
            <Col xs={24}>
              <PageHeaderButton block data-testid={CREATE_BUTTON_DATA_TEST_ID} type="primary-blue" onClick={onCreate}>
                Create Message
              </PageHeaderButton>
            </Col>
          </Row>,
        ]}
      />
      <StyledUserContent type={UserContentLayoutType.TABLE} mobileFooterMargin={77}>
        <Table<UserTableDataSourceType, IGetMessagesRequestParams>
          request={onRequest}
          columns={!isMobile ? column : columnMobile}
          rowKey="id"
          rowClassName={rowClassName}
          transformQueryFn={transformQuery}
          onRow={(record: UserTableDataSourceType) => {
            return {
              onClick: () => handleRowClick(record),
            };
          }}
        />
      </StyledUserContent>
    </>
  );
});

export default MessageCenterDashboard;
