import React from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import { Breadcrumb } from 'antd';
import { Spin } from 'antd5';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { useSelector } from 'react-redux';

import { generateBreadcrumb } from '../common/utils';
import { TitleRow, TitleCol, StyledContainer } from '../common/styles';
import { getUser } from '../../../../state/selectors/auth';
import { getScholarships } from '../../../../apis/users/scholarship-match';
import { ScholarshipList } from '../common/scholarship-list';
import { tenantLabel } from '../../../../siteContent';
import theme from '../../../../theme';
import { NavigationWrapper } from '../../../../components/common/page-header';
import Loader from '../../../../components/common/loader';

const Wrapper = styled.div`
  font-size: 18px;
  color: ${theme.colorsBlack};
  line-height: 28px;
  margin-bottom: 20px;
`;

type ScholarshipMatchListProps = {
  path?: string;
};

export const StyledMessage = styled.div`
  color: ${theme.colorsBlack};
  font-weight: bold;
  font-size: 18px;
  margin: 20px 20px;
  text-align: center;
  word-break: break-word;
`;

export function ScholarshipMatchList(_path: RouteComponentProps<ScholarshipMatchListProps>) {
  const userInfo = useSelector(getUser);

  const {
    data: scholarships,
    isLoading,
    error,
  } = useQuery(
    userInfo && [getScholarships.QUERY_KEY, { userId: userInfo.id }],
    ({ queryKey }: { queryKey: [string, { userId: string }] }) => getScholarships(queryKey[1])
  );

  const renderContent = () => {
    if (isLoading) {
      return <Loader />;
    }
    return (
      <StyledContainer>
        <NavigationWrapper>
          <Breadcrumb separator={<NavigateNext />}>
            {generateBreadcrumb(
              { [`${tenantLabel} Dashboard`]: '/myhsf/dashboard' },
              { 'Recommended Scholarships': '' }
            ).map((item, index) => (
              <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </NavigationWrapper>
        <TitleRow>
          <TitleCol>Recommended Scholarships</TitleCol>
        </TitleRow>
        <Wrapper>
          Based on the information you’ve supplied, we recommend you apply to the following scholarship opportunitiess.
          {/* Based on your gender, school year, GPA and ethnicity here are some other recommended scholarships you might be
          interested in. */}
        </Wrapper>
        <Spin spinning={isLoading}>
          {error && <StyledMessage>{error.message}</StyledMessage>}
          {!error && (
            <ScholarshipList
              dataSource={scholarships || []}
              onHandleDetail={(id: string) => navigate(`/myhsf/scholarships/${id}`)}
            />
          )}
        </Spin>
      </StyledContainer>
    );
  };
  return <StyledWhiteBackground>{renderContent()}</StyledWhiteBackground>;
}

export default ScholarshipMatchList;

const StyledWhiteBackground = styled.div`
  background-color: ${theme.colorsWhite};
`;
